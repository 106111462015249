import {
  Avatar,
  Box,
  Flex,
  HStack,
  SimpleGrid,
  Stack,
  Text
} from '@chakra-ui/react';
import InfiniteScrollHelper from 'components/InfiniteScrollHelper';
import LoadingWrapper from 'components/LoadingWrapper';
import { format } from 'date-fns';
import { useCard } from 'providers/CardProvider';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import api from 'utils/api';

const SurveyAnswerList = ({ filter, code, onSuccess }) => {
  const { openCard } = useCard();
  const { slug } = useParams();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const {
    status: loadingStatus,
    data: answers,
    fetchNextPage,
    isFetching,
    isSuccess,
    hasNextPage,
    refetch: fetchCards
  } = useInfiniteQuery(
    'answers',
    async ({ pageParam = 0 }) => {
      const params = new URLSearchParams({
        offset: pageParam,
        limit: 20
      });
      params.append('hive', slug);
      if (filter?.collection) {
        params.append('collection', filter.collection);
      }
      if (filter?.creator) {
        params.append('contributors', filter.creator);
      }
      if (filter?.assignee) {
        params.append('assignees', filter.assignee);
      }
      if (filter?.step) {
        if (filter.step === -1) {
          params.append('step__isnull', true);
        } else {
          params.append('step', filter.step);
        }
      }
      if (filter?.step__isnull) {
        params.append('step__isnull', filter.step__isnull);
      }
      if (filter?.tags) {
        filter.tags.forEach(tag => {
          params.append('tags', tag);
        });
      }
      if (filter?.workflow) {
        params.append('collection__workflow', filter.workflow);
      }
      if (filter?.ordering) {
        params.append('ordering', filter.ordering);
      }
      if (filter?.search) {
        params.append('search', filter.search);
      }
      if (filter?.type) {
        params.append('collection__workflow__type', filter.type);
      }
      if (code) {
        params.append('code', code);
      }
      params.append('hive__slug', slug);
      const { data } = await api.get(`/cards?${params.toString()}`);
      if (onSuccess) {
        onSuccess(data.results);
      }
      return data;
    },
    {
      getNextPageParam: (lastPage, _) => {
        if (lastPage.next) {
          var url = new URL(lastPage.next);
          var offset = url.searchParams.get('offset');
          return offset;
        }
        return null;
      },
      enabled: false
    }
  );

  useEffect(() => {
    queryClient.removeQueries('cards');
    fetchCards();
  }, [
    fetchCards,
    queryClient,
    filter?.assignee,
    filter?.collection,
    filter?.creator,
    filter?.step,
    filter?.step__isnull,
    filter?.workflow,
    filter?.ordering,
    filter?.search,
    filter?.tags,
    filter?.type
  ]);

  return (
    <Box width="full">
      <LoadingWrapper
        statuses={[loadingStatus]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.cards').toLowerCase()
          })
        ]}
      >
        {isSuccess && answers.pages[0].count > 0 && (
          <InfiniteScrollHelper
            hasMore={!isFetching && hasNextPage}
            loadMore={fetchNextPage}
          >
            <SimpleGrid width="full" columns={[2, 3, 4]} spacing={4}>
              {answers.pages.map(page =>
                page.results.map(
                  (answer, index) =>
                    answer.contributors.length > 0 && (
                      <Flex
                        key={index}
                        padding={2}
                        borderWidth="1px"
                        borderRadius="lg"
                        onClick={() => {
                          openCard(answer.id);
                        }}
                        cursor="pointer"
                      >
                        <HStack alignItems="flex-start">
                          <Avatar
                            size="sm"
                            src={
                              answer.contributors[0]?.avatar
                                ? answer.contributors[0].avatar
                                : answer.contributors[0]?.third_party_avatar
                                ? answer.contributors[0].third_party_avatar
                                : null
                            }
                            name={answer.contributors[0]?.full_name}
                          />
                          <Stack spacing={0} justifyContent="flex-start">
                            <Text fontSize="sm" fontWeight="bold">
                              {answer.contributors[0].first_name}
                            </Text>
                            <Text fontSize="xs">
                              {format(
                                new Date(answer.created_at),
                                'dd MMM yyyy'
                              )}
                            </Text>
                          </Stack>
                        </HStack>
                      </Flex>
                    )
                )
              )}
            </SimpleGrid>
          </InfiniteScrollHelper>
        )}
      </LoadingWrapper>
    </Box>
  );
};

export default SurveyAnswerList;
