import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  Heading,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import CollectionList from './CollectionList';
import CollectionCreationForm from './CollectionCreationForm';
import ModalWrapper from 'components/ModalWrapper';

const MyCollections = () => {
  const [newCollection, setNewCollection] = useState(null);

  const navigate = useNavigate();
  const { slug } = useParams();
  const { data: me } = useQuery('me');
  const { t } = useTranslation();

  const {
    isOpen: showCreateCollectionModal,
    onOpen: openCreateCollectionModal,
    onClose: closeCreateCollectionModal
  } = useDisclosure();

  const handleOnCloseCreateCollectionModal = () => {
    closeCreateCollectionModal();
    if (newCollection) {
      navigate(`/${slug}/collection/${newCollection.id}`);
    }
  };

  return me ? (
    <>
      <Container height="full" maxW="container.lg" py={8}>
        <Stack spacing={8} height="full">
          <Heading fontSize="3xl">{t('common.my_collections')}</Heading>
          <Text>{t('collection.instructions')}</Text>
          <CollectionList
            managerId={me.id}
            openCreateCollectionModal={openCreateCollectionModal}
          />
        </Stack>
      </Container>
      <ModalWrapper
        size="full"
        isOpen={showCreateCollectionModal}
        onClose={handleOnCloseCreateCollectionModal}
        hasSteps
      >
        <CollectionCreationForm
          isOpen={showCreateCollectionModal}
          onClose={handleOnCloseCreateCollectionModal}
          onCollectionCreated={newCollection => {
            setNewCollection(newCollection);
          }}
          newCollection={newCollection}
        />
      </ModalWrapper>
    </>
  ) : null;
};

export default MyCollections;
