import {
  Container,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure
} from '@chakra-ui/react';
import { useUi } from 'providers/UiProvider';
import ConfirmationModal from './modals/ConfirmationModal';
import { useTranslation } from 'react-i18next';

const ModalWrapper = ({
  title,
  titleProps = { fontSize: 'xl', noOfLines: 2 },
  headerContent,
  children,
  hasSteps,
  pb = 4,
  isOpen,
  onClose,
  confirmClosure = false,
  ...rest
}) => {
  const {
    isOpen: showConfirmationModal,
    onOpen: openConfirmationModal,
    onClose: closeConfirmationModal
  } = useDisclosure();

  const { modalClosureLocked, setModalClosureLocked } = useUi();

  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        if (confirmClosure && modalClosureLocked) {
          openConfirmationModal();
        } else {
          onClose();
        }
      }}
      {...rest}
    >
      <ModalOverlay />
      <ModalContent>
        {rest.size === 'full' ? (
          <ModalHeader />
        ) : headerContent ? (
          <ModalHeader>{headerContent}</ModalHeader>
        ) : (
          <ModalHeader>
            <Heading {...titleProps}>{title}</Heading>
          </ModalHeader>
        )}
        <ModalCloseButton />
        <ModalBody pb={pb}>
          {rest.size === 'full' ? (
            <Container
              maxWidth={
                rest.maxWidth ? rest.maxWidth : ['full', null, 'container.sm']
              }
              padding={[0, 'auto', 'auto']}
            >
              <Stack spacing={8}>
                {!hasSteps && headerContent ? (
                  <ModalHeader>{headerContent}</ModalHeader>
                ) : (
                  <Heading {...titleProps}>{title}</Heading>
                )}
                {children}
              </Stack>
            </Container>
          ) : (
            children
          )}
        </ModalBody>
      </ModalContent>
      <ConfirmationModal
        deleteHeader={t('confirmation.unsaved_changes')}
        deleteText={t('confirmation.unsaved_changes_explainer')}
        isOpen={showConfirmationModal}
        onClose={closeConfirmationModal}
        onDelete={() => {
          closeConfirmationModal();
          setModalClosureLocked(false);
          onClose();
        }}
        buttonText={t('confirmation.discard')}
      />
    </Modal>
  );
};

export default ModalWrapper;
