import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  Badge,
  HStack,
  Show,
  Text,
  Tooltip
} from '@chakra-ui/react';
import { faPenField } from '@fortawesome/pro-solid-svg-icons';
import AccordionBase from 'components/AccordionBase';
import BadgeIcon from 'components/BadgeIcon';
import { isCurrentStep } from 'features/workflow/workflowUtils';
import CardStep from './CardStep';

const CardStepAccordions = ({ card, code }) => {
  const { t } = useTranslation();

  let defaultIndex = card.collection?.workflow?.steps?.findIndex(step =>
    isCurrentStep(step.id, card.step?.id)
  );

  return card ? (
    <Accordion
      key={defaultIndex}
      allowMultiple
      borderColor="rgba(1, 1, 1, 0)"
      defaultIndex={defaultIndex === -1 ? [] : [defaultIndex]}
    >
      {card.collection?.workflow?.steps?.map(step => {
        const event = card?.step_events
          ?.reverse()
          ?.find(e => e.step?.id === step.id);
        const selected = isCurrentStep(step.id, card.step?.id);
        return (
          <AccordionBase
            key={step.id}
            selected={selected}
            left={
              <HStack spacing={4}>
                <BadgeIcon icon={faPenField} colorScheme={step.color} />
                <Text whiteSpace="nowrap">{step?.name}</Text>
              </HStack>
            }
            right={
              <Show above="lg">
                <HStack pr={2}>
                  {selected && (
                    <Badge colorScheme="blue">
                      {t('workflow.current_step')}
                    </Badge>
                  )}
                  {event && (
                    <Tooltip
                      hasArrow
                      label={format(
                        new Date(event.created_at),
                        'dd MMM yyyy HH:mm'
                      )}
                      placement="bottom"
                    >
                      <Badge variant="outline">
                        {format(new Date(event.created_at), 'dd MMM yyyy')}
                      </Badge>
                    </Tooltip>
                  )}
                </HStack>
              </Show>
            }
          >
            <CardStep code={code} card={card} filter={{ step: step.id }} />
          </AccordionBase>
        );
      })}
    </Accordion>
  ) : (
    <CardStep card={card} code={code} filter={{ step: card.step?.id }} />
  );
};

export default CardStepAccordions;
