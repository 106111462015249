import { useTeams } from 'msteams-react-base-component';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Heading,
  HStack,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react';
import EmptyState from 'components/EmptyState';
import Heatmap from 'components/Heatmap';
import CardDrawer from 'features/card/CardDrawer';
import CardList from 'features/card/CardList';
import AverageScore from 'features/collection/AverageScore';
import CollectionWordcloud from 'features/collection/CollectionWordcloud';
import TagFilter from 'features/collection/TagFilter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faSliders } from '@fortawesome/pro-regular-svg-icons';
import CopyLinkButton from 'components/CopyLinkButton';
import { getCollectionShareUrl } from 'components/shareUtils';
import IconTooltip from 'components/tooltips/IconTooltip';
import { useQuery } from 'react-query';
import api from 'utils/api';
import FilterDrawer from 'components/FilterDrawer';
import { getActiveStepFilters } from 'features/workflow/workflowUtils';
import StepVoteResults from './StepVoteResults';

const StepDetailed = ({ collection, filter, onClickCard, setFilter }) => {
  const {
    isOpen: showFilterDrawer,
    onOpen: openFilterDrawer,
    onClose: closeFilterDrawer
  } = useDisclosure();

  const { slug } = useParams();
  const [{ inTeams }] = useTeams();
  const [word, setWord] = useState(null);
  const [tags, setTags] = useState(null);
  const [options, setOptions] = useState({});
  const [tabIndex, setTabIndex] = useState(0);

  const { t } = useTranslation();

  const {
    status: popularityStatus,
    data: popularity,
    isSuccess: popularityIsSuccess
  } = useQuery(['popularity', collection.id, filter.step], async () => {
    const params = new URLSearchParams();
    if (collection.id) {
      params.append('collection', collection.id);
    }
    if (filter.step) {
      if (filter.step === -1 || filter.step === null) {
        params.append('step__isnull', 'true');
      } else {
        params.append('step', filter.step);
      }
    }
    params.append('hive', slug);
    const { data } = await api.get(`/cards/popularity?${params.toString()}`);
    return data;
  });

  useEffect(() => {
    setTabIndex(0);
  }, [filter.step]);

  const handleOnWordClick = useCallback((_, { text }) => {
    setWord(text);
  }, []);

  const wordcloud = useMemo(
    () => (
      <CollectionWordcloud
        collectionId={collection.id}
        step={filter?.step}
        emptyState={
          <Text textAlign="center" variant="muted">
            {t('common.nothing_here_yet')}
          </Text>
        }
        onWordClick={handleOnWordClick}
      />
    ),
    [collection.id, filter.step, handleOnWordClick, t]
  );

  const handleOnChangeTags = tags => {
    if (tags && tags.length > 0) {
      setTags(tags.map(t => t.id));
    } else {
      setTags(null);
    }
  };

  const getEmptyState = step => {
    if (step) {
      return (
        <EmptyState
          title={t('collection.empty_state_step')}
          filename="ladder.svg"
          borderWidth={0}
        />
      );
    }
    return (
      <EmptyState
        title={t('common.nothing_here_yet')}
        filename="tea.svg"
        borderWidth={0}
      />
    );
  };

  const noOfActiveFilters = getActiveStepFilters({ ...filter, options });

  const FilterDrawerButton = () => {
    return (
      <HStack justifyContent="flex-end" spacing={4}>
        {noOfActiveFilters > 0 && (
          <Text>
            {noOfActiveFilters === 1
              ? t('common.nr_filters_active_with_count_one', {
                  nr: noOfActiveFilters
                })
              : t('common.nr_filters_active_with_count_several', {
                  nr: noOfActiveFilters
                })}
          </Text>
        )}
        <Button
          variant="outline"
          onClick={() => {
            showFilterDrawer ? closeFilterDrawer() : openFilterDrawer();
          }}
        >
          <FontAwesomeIcon icon={faSliders} />
        </Button>
      </HStack>
    );
  };

  return (
    <Stack spacing={8}>
      <HStack>
        <Heading fontSize="3xl">{collection.title}</Heading>
        <Tooltip hasArrow label={t('collection.change_step_tooltip')}>
          <Box>
            <FontAwesomeIcon icon={faInfoCircle} />
          </Box>
        </Tooltip>
      </HStack>
      <Tabs
        size="sm"
        variant="fresh"
        index={tabIndex}
        onChange={tabIndex => {
          setTabIndex(tabIndex);
        }}
      >
        <SimpleGrid
          templateColumns={['1fr', null, '1fr 200px']}
          gap={4}
          overflowX="auto"
        >
          <TabList>
            <Tab>{t('common.gallery_view')}</Tab>
            <Tab>{t('common.list_view')}</Tab>
            <Tab>{t('common.wordcloud')}</Tab>
            {collection.workflow && <Tab>{t('common.heatmap')}</Tab>}
            {collection.workflow && <Tab>{t('common.voting')}</Tab>}
            {collection.workflow && <Tab>{t('common.scoring')}</Tab>}
          </TabList>
          {!inTeams && (
            <HStack justifyContent="flex-end">
              <IconTooltip
                alignSelf="center"
                label={t('step.visibility_when_share_link')}
              />
              <CopyLinkButton
                url={getCollectionShareUrl(slug, collection, filter)}
              />
            </HStack>
          )}
        </SimpleGrid>
        <TabPanels>
          <TabPanel py={8}>
            <Stack spacing={4}>
              <FilterDrawerButton />
              <CardList
                filter={{
                  ...filter,
                  options
                }}
                emptyState={getEmptyState(filter.step)}
                onClick={onClickCard}
                useCarousel
              />
            </Stack>
          </TabPanel>
          <TabPanel py={8}>
            <Stack spacing={4}>
              {collection?.tags?.length > 0 && (
                <TagFilter
                  tags={collection.tags}
                  onChange={handleOnChangeTags}
                />
              )}
              <CardList
                filter={{ ...filter, tags }}
                emptyState={getEmptyState(filter.step)}
                onClick={onClickCard}
                tableView
                useCarousel
              />
            </Stack>
          </TabPanel>
          <TabPanel py={8} rounded="lg" borderWidth={1} mt={8}>
            {wordcloud}
          </TabPanel>
          {collection.workflow && (
            <TabPanel py={8} rounded="lg" borderWidth={1} mt={8}>
              <Heatmap
                data={popularity}
                isSuccess={popularityIsSuccess}
                status={popularityStatus}
                emptyState={getEmptyState(filter.step)}
              />
            </TabPanel>
          )}
          {collection.workflow && (
            <TabPanel py={8}>
              <StepVoteResults
                collectionId={collection.id}
                workflowId={collection.workflow.id}
                filter={filter}
              />
            </TabPanel>
          )}
          {collection.workflow && (
            <TabPanel py={4}>
              <AverageScore
                collectionId={collection.id}
                workflowId={collection.workflow.id}
                stepId={filter.step}
              />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
      <CardDrawer
        filter={{ search: word }}
        header={word}
        isOpen={word}
        onClose={() => setWord(null)}
      />
      <FilterDrawer
        isOpen={showFilterDrawer}
        collection={collection}
        filter={{ ...filter, options }}
        setFilter={setFilter}
        onClose={closeFilterDrawer}
        onChangeOptions={options => {
          setOptions(options);
          setFilter({ ...filter });
        }}
      />
    </Stack>
  );
};

export default StepDetailed;
