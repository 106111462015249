// import { pages } from '@microsoft/teams-js';
import { useTeams } from 'msteams-react-base-component';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Button, HStack, MenuDivider, Show, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AvatarGroupButton from 'components/AvatarGroupButton';
import ActionMenu, {
  CustomMenuItem,
  DeleteMenuItem,
  EditMenuItem
} from 'components/ActionMenu';
import AttachmentUploadComponent from 'features/attachment/AttachmentUploadComponent';
import {
  faFileExport,
  faHome,
  faLink,
  faPlug,
  //faShare,
  faUserGroup
} from '@fortawesome/pro-regular-svg-icons';
import { Plans, hasPlanGreaterThanOrEqual } from 'features/hive/hiveUtils';
//import { getCollectionShareUrl } from 'components/shareUtils';

const CollectionControls = ({
  collection,
  onClickConnectProject,
  onClickDelete,
  onClickEdit,
  onClickExport,
  onClickManagers,
  onClickPublish,
  onClickShare
}) => {
  const [{ inTeams }] = useTeams();
  const { slug } = useParams();
  const { data: hive } = useQuery(['hive', slug]);

  const { t } = useTranslation();

  return (
    <HStack>
      <Show above="lg">
        <Text variant="muted">{t('common.managers')}</Text>
        <AvatarGroupButton
          max={3}
          members={collection.managers}
          onClick={onClickManagers}
        />
        {!inTeams && hive.use_share && (
          <Button variant="outline" onClick={onClickShare}>
            <HStack>
              <FontAwesomeIcon icon={faLink} />
              <Text>{`${t('common.share')} / ${t('common.invite')}`}</Text>
            </HStack>
          </Button>
        )}
      </Show>
      <ActionMenu>
        <CustomMenuItem
          icon={faUserGroup}
          label={t('collection.select_managers')}
          onClick={onClickManagers}
        />
        <MenuDivider />
        {/* {inTeams && (
          <CustomMenuItem
            icon={faShare}
            label={`${t('collection.share_in_teams')}`}
            onClick={() => {
              pages.shareDeepLink({
                subPageId: collection.code ? collection.code : collection?.id,
                subPageLabel: collection.title,
                subPageWebUrl:
                  'https://teams.microsoft.com/l/entity/6713ba24-6f04-4603-9483-ebeb54815958/tdemo-sv?webUrl=https://app.hives.co/demo.sv&label=Hives'
              });
              pages.shareDeepLink({
                subPageId: collection.code ? collection.code : collection?.id,
                subPageLabel: collection.title,
                subPageWebUrl:
                  'https://teams.microsoft.com/l/entity/6713ba24-6f04-4603-9483-ebeb54815958/tdemo-sv?webUrl=https://app.stage.hives.co/demo.sv&label=Hives'
              });
            }}
          />
        )} */}
        {hive.use_share && (
          <CustomMenuItem
            icon={faLink}
            label={`${t('common.share')} / ${t('common.invite')}`}
            onClick={onClickShare}
          />
        )}
        <CustomMenuItem
          icon={faHome}
          label={t('collection.publish_on_home')}
          onClick={onClickPublish}
        />
        {hasPlanGreaterThanOrEqual(hive?.plan, Plans.Professional) && (
          <CustomMenuItem
            icon={faPlug}
            label={t('collection.connect_to_project')}
            onClick={onClickConnectProject}
          />
        )}
        <MenuDivider />
        <CustomMenuItem
          icon={faFileExport}
          label={t('collection.export_collection')}
          onClick={onClickExport}
        />
        <MenuDivider />
        <AttachmentUploadComponent
          modelConfig={{
            singular: 'collection',
            plural: 'collections',
            modelId: collection.id
          }}
        />
        <MenuDivider />
        <EditMenuItem onClick={onClickEdit} />
        <DeleteMenuItem onClick={onClickDelete} />
      </ActionMenu>
    </HStack>
  );
};

export default CollectionControls;
