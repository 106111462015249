import { useEffect } from 'react';
import { Button, Flex, useColorModeValue } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/pro-regular-svg-icons';
import { useCard } from 'providers/CardProvider';

const CarouselButton = ({ icon, onClick, ...rest }) => (
  <Button
    position="sticky"
    pointerEvents="all"
    top="45%"
    onClick={onClick}
    bg={useColorModeValue('white', 'gray.700')}
    {...rest}
  >
    <FontAwesomeIcon icon={icon} />
  </Button>
);

const CarouselControls = () => {
  const { nextCard, previousCard } = useCard();

  useEffect(() => {
    const handleKeyDown = event => {
      switch (event.key) {
        case 'ArrowLeft':
          previousCard();
          break;
        case 'ArrowRight':
          nextCard();
          break;
        default:
          break;
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [nextCard, previousCard]);

  return (
    <Flex
      position="absolute"
      height="full"
      zIndex={1}
      pointerEvents="none"
      width="full"
    >
      <Flex position="relative" width="50%">
        <CarouselButton icon={faChevronLeft} onClick={previousCard} ml={-16} />
      </Flex>
      <Flex position="relative" width="50%" justifyContent="flex-end">
        <CarouselButton icon={faChevronRight} onClick={nextCard} mr={-16} />
      </Flex>
    </Flex>
  );
};

export default CarouselControls;
