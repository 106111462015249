import { Center, Text } from '@chakra-ui/react';

const NotificationBadge = ({
  unreadNotifications,
  offset = 1.0,
  position = 'absolute'
}) => {
  return unreadNotifications ? (
    <Center
      position={position}
      bg="red.500"
      color="white"
      fontSize="xs"
      size={4}
      top={position === 'static' ? null : 1 / offset}
      right={position === 'static' ? null : 1.5 / offset}
      px={unreadNotifications > 9 ? 1 : 0}
      minWidth="16px"
      minHeight="16px"
      rounded="full"
    >
      <Text>{unreadNotifications > 9 ? '9+' : unreadNotifications}</Text>
    </Center>
  ) : null;
};

export default NotificationBadge;
