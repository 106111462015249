import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  AspectRatio,
  Button,
  Container,
  Flex,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import ButtonAnimator from 'components/ButtonAnimator';
import CoverMedia from 'components/CoverMedia';
import LoginModal from 'components/modals/LoginModal';
import LoadingWrapper from 'components/LoadingWrapper';
import ModalWrapper from 'components/ModalWrapper';
import TitleDescription from 'components/TitleDescription';
import AttachmentList from 'features/attachment/AttachmentList';
import CardForm from 'features/card/CardForm';
import CardList from 'features/card/CardList';
import {
  cardFilterToArray,
  generateCardPayload
} from 'features/card/cardUtils';
import NameModal from 'features/member/NameModal';
import GuestHeader from 'features/navigation/GuestHeader';
import LoginHeader from 'features/navigation/LoginHeader';
import { useConfetti } from 'providers/ConfettiProvider';
import { useAuth } from 'providers/AuthProvider';
import { useUi } from 'providers/UiProvider';
import api from 'utils/api';
import CollectionSearchFilter from './CollectionSearchFilter';
import CollectionCardFilter from './CollectionCardFilter';
import { getCollectingPlural, getCollectingSingular } from './collectionUtils';
import { getContributeButtonLabel } from 'features/workflow/workflowUtils';
import Scrollable from 'components/Scrollable';

const SharedCollectionDetailed = () => {
  const { authenticated } = useAuth();
  const { setShowMobileCreateButton } = useUi();
  const [showFilter, setShowFilter] = useState(false);
  const { filter, setFilter } = useUi();

  const { fire } = useConfetti();
  const { code, slug } = useParams();

  const navigate = useNavigate();
  const toast = useToast();

  const {
    isOpen: showLoginModal,
    onOpen: openLoginModal,
    onClose: closeLoginModal
  } = useDisclosure();

  const {
    isOpen: showCreateCardModal,
    onOpen: openCreateCardModal,
    onClose: closeCreateCardModal
  } = useDisclosure();

  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const [searchParams] = useSearchParams();

  const {
    data: collection,
    status,
    isSuccess
  } = useQuery(
    ['collection', code],
    async () => {
      const { data } = await api.get(
        `/shared/collections/${code}?hive__slug=${slug}`
      );
      return data;
    },
    {
      onSuccess: collection => {
        if (!collection.guest) {
          const card = searchParams.get('card');
          if (card) {
            navigate(`/${slug}/collection/${collection.id}?card=${card}`);
          } else {
            navigate(`/${slug}/collection/${collection.id}`);
          }
        } else {
          setShowMobileCreateButton(collection.open);
        }
      }
    }
  );

  const handleOnCardCreateSuccess = card => {
    const cards = queryClient.getQueryData([
      'cards',
      { hive: slug },
      ...cardFilterToArray({ ...filter })
    ]);
    const pages = cards.pages.map(page => {
      return {
        ...page,
        count: page.count + 1
      };
    });
    if (pages.length > 0) {
      pages[0].results = [card, ...pages[0].results];
    }
    queryClient.setQueryData(
      ['cards', { hive: slug }, ...cardFilterToArray({ ...filter })],
      {
        ...cards,
        pages
      }
    );
    closeCreateCardModal();
    fire();
    toast({
      title: t('card.contribution_thank_you'),
      status: 'success',
      position: 'bottom-right',
      isClosable: true
    });
  };

  const createCardMutation = useMutation(
    card => api.post(`/cards?hive=${slug}`, card, { timeout: 0 }),
    {
      onSuccess: ({ data: card }) => {
        handleOnCardCreateSuccess(card);
      }
    }
  );

  const createAnonymousCardMutation = useMutation(
    card =>
      api.post(
        `/shared/collections/${code}/contribute?hive__slug=${slug}`,
        card,
        { timeout: 0 }
      ),
    {
      onSuccess: ({ data: card }) => {
        handleOnCardCreateSuccess(card);
      }
    }
  );

  const handleOnClickCreateCard = () => {
    if (authenticated || collection.accept_anonymous_contributions) {
      openCreateCardModal();
    } else {
      openLoginModal();
    }
  };

  const handleOnCreateCard = async card => {
    const payload = generateCardPayload(collection.id, card);
    if (authenticated) {
      await createCardMutation.mutateAsync(payload);
    } else if (collection.accept_anonymous_contributions) {
      await createAnonymousCardMutation.mutateAsync(payload);
    }
  };

  return (
    <Flex flexDirection="column">
      {status === 'success' ? (
        authenticated ? (
          <GuestHeader
            darkLogo={collection.hive.dark_logo}
            lightLogo={collection.hive.light_logo}
          />
        ) : (
          <LoginHeader
            darkLogo={collection.hive.dark_logo}
            lightLogo={collection.hive.light_logo}
            openLoginModal={openLoginModal}
          />
        )
      ) : null}
      <Scrollable hasHeader>
        <Container marginY={8} maxW="container.lg">
          <LoadingWrapper
            statuses={[status]}
            errorMessages={[
              t('common.could_not_fetch_data_please_try_again_later', {
                data: t('common.collection').toLowerCase()
              })
            ]}
          >
            {isSuccess ? (
              <>
                <Stack spacing={8}>
                  <Text
                    as="a"
                    target="_blank"
                    href="https://www.hives.co"
                    variant="muted"
                    alignSelf="flex-start"
                  >
                    {t('collection.powered_by_hives_co')}
                  </Text>
                  <SimpleGrid columns={[1, null, 2]} spacing={8}>
                    <AspectRatio ratio={16 / 9}>
                      <CoverMedia object={collection} />
                    </AspectRatio>
                    <Stack
                      spacing={8}
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      <TitleDescription
                        title={collection?.title}
                        description={collection?.description}
                        noOfLines={5}
                      />
                      <Flex
                        width="full"
                        justifyContent={['flex-end', null, 'flex-start']}
                      >
                        <Tooltip
                          isDisabled={collection.open}
                          label={t('collection.closed_collection_tooltip')}
                          shouldWrapChildren
                        >
                          <ButtonAnimator active={collection.open}>
                            <Button
                              colorScheme="teal"
                              isDisabled={!collection.open}
                              onClick={handleOnClickCreateCard}
                            >
                              {getContributeButtonLabel(collection.workflow)}
                            </Button>
                          </ButtonAnimator>
                        </Tooltip>
                      </Flex>
                    </Stack>
                  </SimpleGrid>
                  <AttachmentList
                    code={code}
                    modelConfig={{
                      singular: 'collection',
                      plural: 'collections',
                      modelId: collection.id
                    }}
                    canEdit={false}
                  />
                  <Flex alignItems="center" justifyContent="space-between">
                    <Heading fontSize="xl">
                      {getCollectingPlural(collection.type)}
                    </Heading>
                  </Flex>
                  <CollectionSearchFilter
                    collection={collection}
                    filter={filter}
                    setFilter={setFilter}
                    showFilter={showFilter}
                    toggleFilter={() => {
                      setShowFilter(!showFilter);
                    }}
                  />
                  {showFilter && (
                    <CollectionCardFilter
                      collection={collection}
                      filter={filter}
                      setFilter={setFilter}
                    />
                  )}
                  <CardList code={code} filter={filter} />
                </Stack>
                <ModalWrapper
                  title={getCollectingSingular(collection.type)}
                  size="full"
                  isOpen={showCreateCardModal}
                  onClose={closeCreateCardModal}
                >
                  <CardForm
                    titleOverride={collection.workflow?.title_override}
                    useDescription={collection.workflow?.use_card_description}
                    useImage={collection.workflow?.use_card_image}
                    workflowId={collection.workflow?.id}
                    code={code}
                    collection={collection}
                    isOpen={showCreateCardModal}
                    onSubmit={handleOnCreateCard}
                  />
                </ModalWrapper>
              </>
            ) : null}
          </LoadingWrapper>
        </Container>
      </Scrollable>
      <LoginModal isOpen={showLoginModal} onClose={closeLoginModal} />
      <NameModal />
    </Flex>
  );
};

export default SharedCollectionDetailed;
