import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Skeleton,
  Stack,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { useTheme } from '@emotion/react';
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Select } from 'chakra-react-select';
import LoadingWrapper from 'components/LoadingWrapper';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import api from 'utils/api';

const formatDates = (data, truncate, groups) => {
  return data.map(entry => {
    const currentDate = new Date(entry.date);
    let formattedDate;

    switch (truncate) {
      case 'day':
        formattedDate = `${currentDate.toLocaleString([], {
          weekday: 'short'
        })}`;
        break;
      case 'week':
        formattedDate = currentDate.toLocaleString([], {
          day: 'numeric',
          month: 'short'
        });
        break;
      case 'month':
        formattedDate = currentDate.toLocaleString([], { month: 'short' });
        break;
      default:
        formattedDate = currentDate.toISOString().split('T')[0];
        break;
    }

    // //randomly assigning a group for now
    // let randomGroup = 0;
    // if (groups?.lenth > 0) {
    //   randomGroup = groups[Math.floor(Math.random() * groups.length)]?.id;
    // }

    // console.log(groups);
    return {
      date: formattedDate,
      joins: entry.joins
      //group: randomGroup
    };
  });
};

const TimespanAreaChart = ({ endpoint, filter, groups, queryKey }) => {
  const { slug } = useParams();
  const { t } = useTranslation();

  const { startDate, endDate, truncate } = useMemo(() => {
    const startDate = filter?.startDate
      ? new Date(filter?.startDate)
      : new Date();
    const endDate = filter?.startDate
      ? new Date(filter?.startDate)
      : new Date();
    let truncate = null;

    switch (filter?.span) {
      case 'week':
        truncate = 'day';
        const day = startDate.getDay();
        const diff = startDate.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is Sunday
        startDate.setDate(diff);
        endDate.setDate(startDate.getDate() + 6);
        break;
      case 'month':
        truncate = 'week';
        startDate.setDate(1);
        endDate.setMonth(startDate.getMonth() + 1);
        endDate.setDate(1);
        break;
      case 'year':
        truncate = 'month';
        startDate.setMonth(0);
        startDate.setDate(1);
        endDate.setMonth(11);
        endDate.setDate(31);
        break;
      default:
        truncate = 'day';
        const defaultDay = startDate.getDay();
        const defaultDiff =
          startDate.getDate() - defaultDay + (defaultDay === 0 ? -6 : 1);
        startDate.setDate(defaultDiff);
        endDate.setDate(startDate.getDate() + 6);
    }

    if (startDate > endDate) {
      const previousEndDate = new Date(endDate.getDate());
      endDate.setDate(new Date(startDate.getDate()));
      startDate.setDate(new Date(previousEndDate.getDate()));
    }

    return { startDate, endDate, truncate };
  }, [filter.span, filter.startDate]);

  const { data, status, isSuccess } = useQuery(
    [queryKey, slug, filter, startDate, endDate],
    async () => {
      const start = startDate?.toISOString()?.split('T')[0];
      let end = new Date(endDate); //redundant line?
      end = end?.toISOString()?.split('T')[0];
      const { data } = await api.get(
        `${endpoint}?slug=${slug}&start=${start}&end=${end}&truncate=${truncate}&total=${
          filter.metric === 'TOTAL'
        }`
      );
      let formattedData = formatDates([...data], truncate, groups);
      return formattedData;
    }
  );

  const theme = useTheme();
  const fillColors = [
    useColorModeValue(theme.colors.teal['200'], theme.colors.teal['600']),
    useColorModeValue(theme.colors.yellow['200'], theme.colors.yellow['600']),
    useColorModeValue(theme.colors.blue['200'], theme.colors.blue['600']),
    useColorModeValue(theme.colors.pink['200'], theme.colors.pink['600']),
    useColorModeValue(theme.colors.green['200'], theme.colors.green['600']),
    useColorModeValue(theme.colors.purple['200'], theme.colors.purple['600'])
  ];
  const strokeColors = [
    useColorModeValue(theme.colors.teal['400'], theme.colors.teal['200']),
    useColorModeValue(theme.colors.yellow['400'], theme.colors.yellow['200']),
    useColorModeValue(theme.colors.blue['400'], theme.colors.blue['200']),
    useColorModeValue(theme.colors.pink['400'], theme.colors.pink['200']),
    useColorModeValue(theme.colors.green['400'], theme.colors.green['200']),
    useColorModeValue(theme.colors.purple['400'], theme.colors.purple['200'])
  ];
  const gridOpacity = useColorModeValue(1.0, 0.33);

  return (
    <LoadingWrapper
      statuses={[status]}
      errorMessages={[
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.metrics').toLowerCase()
        })
      ]}
      delay={200}
      indicator={
        <Center>
          <Skeleton ml={4} height="40vh" width="80vw" />
        </Center>
      }
    >
      {isSuccess && (
        <Box width="900px" height="300px">
          <Center>
            <ResponsiveContainer width="100%" aspect={3 / 1}>
              <AreaChart
                width={900}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 5,
                  left: 5,
                  bottom: 5
                }}
              >
                <CartesianGrid opacity={gridOpacity} strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis dataKey="joins" allowDecimals={false} />
                <Tooltip fontColor="#111111" />
                <Area
                  type="monotone"
                  dataKey="joins"
                  stroke={strokeColors[0]}
                  fill={fillColors[0]}
                />
                {/* {groups.map((group, index) => (
                  <Area
                    type="monotone"
                    dataKey="joins"
                    stroke={strokeColors[index % strokeColors.length]}
                    fill={fillColors[index % strokeColors.length]}
                  />
                ))} */}
              </AreaChart>
            </ResponsiveContainer>
          </Center>
        </Box>
      )}
    </LoadingWrapper>
  );
};

const getWeekSpan = date => {
  const weekStart = new Date(date);
  weekStart.setDate(date.getDate() - date.getDay() + 1); // Monday
  const weekEnd = new Date(weekStart);
  weekEnd.setDate(weekStart.getDate() + 6); // Sunday
  return `${weekStart.toLocaleString([], {
    day: '2-digit',
    month: 'short'
  })} - ${weekEnd.toLocaleString([], {
    day: '2-digit',
    month: 'short'
  })}, ${weekStart.getFullYear()}`;
};

const TimeSeriesChart = ({ endpoint, queryKey }) => {
  const { t } = useTranslation();
  // const { slug } = useParams();

  const metricOptions = [
    { id: 'NEW', option: t('dashboard.new_members') },
    { id: 'TOTAL', option: t('dashboard.total_members') }
  ];

  const spanOptions = [
    { id: 'week', option: t('dashboard.in_the_week').toLowerCase() },
    { id: 'month', option: t('dashboard.in_the_month').toLowerCase() },
    { id: 'year', option: t('dashboard.in_the_year').toLowerCase() }
  ];

  const [displayDate, setDisplayDate] = useState(new Date());
  const [metric, setMetric] = useState(metricOptions[0]);
  const [span, setSpan] = useState(spanOptions[0]);

  // const {
  //   status: groupsStatus,
  //   data: groups,
  //   isSuccess: groupsIsSuccess
  // } = useQuery('groups', async () => {
  //   const { data } = await api.get(`/groups?hive__slug=${slug}`);
  //   return data;
  // });

  const handleOnStepDisplayDate = useCallback(
    (direction, span) => {
      switch (span) {
        case 'week':
          setDisplayDate(
            new Date(displayDate.setDate(displayDate.getDate() + direction * 7))
          );
          break;
        case 'month':
          setDisplayDate(
            new Date(displayDate.setMonth(displayDate.getMonth() + direction))
          );
          break;
        case 'year':
          setDisplayDate(
            new Date(
              displayDate.setFullYear(displayDate.getFullYear() + direction)
            )
          );
          break;
        default:
          break;
      }
    },
    [displayDate]
  );

  return (
    <Stack spacing={8}>
      <HStack
        spacing={8}
        justifyContent="space-between"
        flexWrap="wrap"
        rowGap={4}
      >
        <HStack spacing={4}>
          <Flex
            minWidth={`${Math.max(
              ...metricOptions.map(option => option.option.length)
            )}ch`}
            maxWidth="full"
          >
            <Select
              //width="max-content" <-- tried, but chakra-react-select ignores all my css attempts
              defaultValue={metricOptions[0]}
              placeholder={t('common.select')}
              options={metricOptions}
              getOptionLabel={option => option.option}
              getOptionValue={option => option?.id}
              onChange={option => {
                setMetric(option);
              }}
              useBasicStyles
            />
          </Flex>
          <Flex
            maxWidth="full"
            minWidth={`${Math.max(
              ...spanOptions.map(option => option.option.length)
            )}ch`}
          >
            <Select
              //width="max-content" <-- tried, but chakra-react-select ignores all my css attempts
              defaultValue={spanOptions[0]}
              placeholder={t('common.select')}
              options={spanOptions}
              getOptionLabel={option => option.option}
              getOptionValue={option => option?.id}
              onChange={option => {
                setSpan(option);
              }}
              useBasicStyles
            />
          </Flex>
        </HStack>
        <HStack spacing={2}>
          <Button
            variant="outline"
            onClick={() => {
              handleOnStepDisplayDate(-1, span.id);
            }}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </Button>
          <Text
            borderWidth="1px"
            borderRadius="md"
            padding={2}
            textAlign="center"
            minWidth="20vw"
            maxWidth="70%"
          >
            {span.id === 'week'
              ? getWeekSpan(displayDate)
              : span.id === 'month'
              ? displayDate.toLocaleString([], {
                  month: span.id === 'month' ? 'short' : '2-digit',
                  year: 'numeric'
                })
              : displayDate.toLocaleString([], {
                  year: 'numeric'
                })}
          </Text>
          <Button
            variant="outline"
            onClick={() => {
              handleOnStepDisplayDate(1, span.id);
            }}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </Button>
        </HStack>
      </HStack>
      <TimespanAreaChart
        endpoint={endpoint}
        filter={{
          metric: metric?.id,
          span: span?.id,
          startDate: new Date(displayDate)
        }}
        queryKey={queryKey}
        //groups={groups}
      />
    </Stack>
  );
};

export default TimeSeriesChart;
