import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Button, Flex, Stack, Textarea } from '@chakra-ui/react';

const CommentForm = ({ onSubmit }) => {
  const { t } = useTranslation();

  const {
    register,
    reset,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful }
  } = useForm({
    mode: 'onChange'
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={6}>
        <Textarea
          onKeyPress={e => {
            if (e.key === 'Enter' && !e.shiftKey) {
              handleSubmit(onSubmit)();
            }
          }}
          placeholder={t('placeholder.add_a_comment')}
          {...register('comment', { required: true })}
        />
        <Flex display={['flex', null, 'flex']} justifyContent="flex-end">
          <Button type="submit" colorScheme="teal" isLoading={isSubmitting}>
            {t('button.comment')}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};

export default CommentForm;
