import { useState } from 'react';
import { Box, Center, Flex, Image, Text } from '@chakra-ui/react';

import ImageDetailed from './ImageDetailed';
import AttachmentActionMenu from './AttachmentActionMenu';

const AttachmentListItem = ({
  attachment,
  onClickDelete,
  onClickUpdate,
  onClickSetAsThumbnail,
  canEdit
}) => {
  const [showImageModal, setShowImageModal] = useState(false);
  const [fileImageSrc, setFileImageSrc] = useState(false);

  const toggleShowImageModal = () => {
    setShowImageModal(!showImageModal);
  };

  // TODO: refactor and use media utils instead of the below regex
  const isImage =
    attachment.file?.toLowerCase().match(/[.](gif|jpg|jpeg|png|tiff|webp)$/i) ||
    attachment.type?.toLowerCase().match(/(image\/|unsplash)/);
  const isFile = attachment instanceof File;

  const readMediaAsURL = file => {
    const reader = new FileReader();
    reader.onload = () => {
      setFileImageSrc(reader.result);
    };
    reader.readAsDataURL(file);
  };

  if (isFile) {
    readMediaAsURL(attachment);
  }

  return (
    <>
      <Flex
        position="relative"
        alignItems="center"
        justifyContent="center"
        height="120px"
        borderWidth={1}
        rounded="lg"
        textAlign="center"
      >
        <Box position="absolute" right={2} top={2}>
          {canEdit && (
            <AttachmentActionMenu
              onClickDelete={() => onClickDelete(attachment)}
              onClickUpdate={() => onClickUpdate(attachment)}
              onClickSetImage={isImage ? onClickSetAsThumbnail : null}
            />
          )}
        </Box>
        <Center
          rounded="lg"
          width="full"
          cursor="pointer"
          onClick={() => {
            if (isImage) {
              toggleShowImageModal();
            }
          }}
        >
          <Image
            src={isFile && fileImageSrc ? fileImageSrc : attachment.file}
            height="120px"
            width="full"
            rounded="lg"
            objectFit="cover"
            fallback={
              <Text
                as="a"
                href={isFile && fileImageSrc ? fileImageSrc : attachment.file}
                download
                noOfLines={2}
                px={2}
                target="_blank"
                wordBreak="break-word"
              >
                {attachment.name}
              </Text>
            }
          />
        </Center>
      </Flex>
      <ImageDetailed
        title={attachment.name}
        src={attachment.file}
        isOpen={showImageModal}
        onClose={toggleShowImageModal}
      />
    </>
  );
};

export default AttachmentListItem;
