import { useTranslation } from 'react-i18next';
import {
  faMemo,
  faRotateRight,
  faShare
} from '@fortawesome/pro-regular-svg-icons';
import ActionMenu, {
  CustomMenuItem,
  DeleteMenuItem,
  EditMenuItem
} from 'components/ActionMenu';
import AttachmentUploadComponent from 'features/attachment/AttachmentUploadComponent';
import ModalWrapper from 'components/ModalWrapper';
import ShareButtons from 'components/ShareButtons';
import { Box, useDisclosure } from '@chakra-ui/react';
import CopyLinkButton from 'components/CopyLinkButton';
import { getCardShareUrl } from 'components/shareUtils';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

const CardActionMenu = ({
  card,
  collection,
  onClickEdit,
  onClickDelete,
  onClickViewReport,
  onClickChangeCollection,
  isAdminOrCollectionManager = false
}) => {
  const { slug } = useParams();
  const { data: hive } = useQuery(['hive', slug]);
  const url = getCardShareUrl(slug, card);

  const { t } = useTranslation();

  const {
    isOpen: showShareButtonsModal,
    onOpen: openShareButtonsModal,
    onClose: closeShareButtonsModal
  } = useDisclosure();

  return (
    <>
      <ActionMenu>
        <EditMenuItem onClick={onClickEdit} />
        <DeleteMenuItem onClick={onClickDelete} />
        <AttachmentUploadComponent
          modelConfig={{
            singular: 'card',
            plural: 'cards',
            modelId: card.id
          }}
        />
        {collection?.share_social_media && hive?.use_share ? (
          <CustomMenuItem
            icon={faShare}
            label={t('common.share')}
            onClick={openShareButtonsModal}
          />
        ) : (
          <CopyLinkButton inMenu url={url} />
        )}

        {isAdminOrCollectionManager && (
          <>
            <CustomMenuItem
              icon={faMemo}
              label={t('card.view_report')}
              onClick={onClickViewReport}
            />
            <CustomMenuItem
              icon={faRotateRight}
              label={t('collection.change_collection')}
              onClick={onClickChangeCollection}
            />
          </>
        )}
      </ActionMenu>
      {collection.share_social_media && hive.use_share && (
        <ModalWrapper
          size="xs"
          isOpen={showShareButtonsModal}
          onClose={closeShareButtonsModal}
          title={t('common.share')}
        >
          <Box pb={4}>
            <ShareButtons
              collection={collection}
              isRound
              label={t('button.copy_link')}
            />
          </Box>
        </ModalWrapper>
      )}
    </>
  );
};

export default CardActionMenu;
