import { useMemo } from 'react';
import { useSortBy, useTable } from 'react-table';
import { useTranslation } from 'react-i18next';
import { useCard } from 'providers/CardProvider';
import ScrollableContainer from 'components/ScrollableContainer';
import IconTooltip from 'components/tooltips/IconTooltip';
import { formatScore } from 'features/chart/chartUtils';
import {
  Box,
  Button,
  HStack,
  Table,
  Tbody,
  Text,
  Td,
  Th,
  Thead,
  Tr,
  Stack,
  Tooltip
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortDown,
  faSortUp
} from '@fortawesome/pro-regular-svg-icons';
import ScorersButton from 'features/card/ScorersButton';

const ScoreTable = ({ chart, results, scores, ...rest }) => {
  const { openCard } = useCard();
  const { t } = useTranslation();

  const sortMembers = useMemo(
    () => (rowA, rowB, columnId) => {
      if (rowA?.values?.[columnId].length > rowB?.values?.[columnId].length)
        return 1;
      if (rowB?.values?.[columnId].length > rowA?.values?.[columnId].length)
        return -1;
      return 0;
    },
    []
  );

  const data = useMemo(() => {
    if (results && scores) {
      const items = [];
      results.forEach(card => {
        let item = {
          id: card.id,
          title: card.title,
          scorers: scores
            .filter(score => score.card === card.id)
            .reduce((scorers, current) => {
              if (!scorers.find(member => member.id === current.creator.id)) {
                return [...scorers, current.creator];
              } else {
                return scorers;
              }
            }, [])
        };
        if (chart.average) {
          card.average_score.forEach((score, index) => {
            item[`param_${index}`] = formatScore(score);
          });
          const left = formatScore(
            card.average_score.reduce((a, b) => a + b, 0)
          );
          const right = formatScore(card.max_score);
          item['total'] = `${left} / ${right}`;
        } else {
          card.total_score.forEach((score, index) => {
            item[`param_${index}`] = formatScore(score);
          });
        }
        items.push(item);
      });
      return items;
    }
    return [];
  }, [results, chart, scores]);

  const columns = useMemo(() => {
    const items = [];
    items.push({
      Header: t('common.title'),
      accessor: 'title',
      textAlign: 'left',
      pr: 8,
      pl: 0
    });
    if (chart) {
      if (chart.average) {
        items.push({
          Header: t('common.total'),
          accessor: 'total',
          textAlign: 'left',
          pr: 8,
          pl: 1
        });
      }
      items.push({
        Header: t('card.scored_by'),
        accessor: 'scorers',
        sortType: sortMembers,
        textAlign: 'left',
        pr: 8,
        pl: 1
      });
      chart.parameters.forEach((parameter, index) => {
        if (parameter.description) {
          items.push({
            Header: <IconTooltip label={parameter.description} />,
            accessor: `${index}.description`,
            textAlign: 'right',
            disableSortBy: true,
            pr: 1,
            pl: 1,
            maxWidth: 1
          });
        }
        items.push({
          Header: parameter.label,
          accessor: `param_${index}`,
          textAlign: 'center',
          pr: 8,
          pl: 1
        });
      });
    }
    return items;
  }, [t, chart, sortMembers]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ data, columns }, useSortBy);
  return (
    <ScrollableContainer
      direction="both"
      isEmpty={results.length < 1}
      {...rest}
    >
      <Table {...getTableProps()}>
        <Thead>
          {headerGroups.map(headerGroup => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  textAlign={column.textAlign}
                  pl={column?.pl}
                  pr={column?.pr}
                  bg={column?.bg}
                >
                  <HStack display="inline-flex" spacing={2}>
                    <Tooltip
                      isDisabled={column?.disableSortBy === true}
                      label={column.render('Header')}
                      hasArrow
                      placement="top"
                    >
                      <Box
                        whiteSpace="nowrap"
                        maxW={['15ch', null, '25ch']}
                        textOverflow="ellipsis"
                        noOfLines={1}
                        display="block"
                        userSelect="none"
                      >
                        {column.render('Header')}
                      </Box>
                    </Tooltip>
                    {!column.disableSortBy && (
                      <Box as="span">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FontAwesomeIcon
                              icon={faSortDown}
                              aria-label="sorted descending"
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faSortUp}
                              aria-label="sorted ascending"
                            />
                          )
                        ) : (
                          <FontAwesomeIcon icon={faSort} />
                        )}
                      </Box>
                    )}
                  </HStack>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <Td
                    {...cell.getCellProps()}
                    isNumeric={cell.column.isNumeric}
                    textAlign={cell.column.textAlign}
                    pl={cell.column?.pl}
                    pr={cell.column?.pr}
                  >
                    {cell.column.id === 'title' ? (
                      <Stack alignItems="flex-start">
                        <Button
                          variant="link"
                          onClick={() => {
                            openCard(cell.row.original.id);
                          }}
                        >
                          <Text
                            textAlign="left"
                            whiteSpace="normal"
                            overflow="hidden"
                            noOfLines={2}
                            width={['120px', null, '200px']}
                          >
                            {cell.render('Cell')}
                          </Text>
                        </Button>
                      </Stack>
                    ) : cell.column.id === 'scorers' ? (
                      <Stack alignItems="flex-start">
                        <ScorersButton
                          scores={scores.filter(
                            score => score.card === cell.row.original.id
                          )}
                          parameters={chart.parameters}
                          isAdminOrCollectionManager={true}
                        />
                      </Stack>
                    ) : (
                      <Text
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        maxW={['15ch', null, '25ch']}
                      >
                        {cell.render('Cell')}
                      </Text>
                    )}
                  </Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </ScrollableContainer>
  );
};
export default ScoreTable;
