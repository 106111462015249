import {
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import api from 'utils/api';
import LoadingWrapper from 'components/LoadingWrapper';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import ScrollableContainer from 'components/ScrollableContainer';
import Description from 'components/Description';
import ModalWrapper from 'components/ModalWrapper';
import AllTextAnswers from './AllTextAnswers';
import { useParams } from 'react-router-dom';

const TextAnswerCarousel = ({ survey, field, code }) => {
  const {
    isOpen: showAllAnswersModal,
    onOpen: openAllAnswersModal,
    onClose: closeAllAnswersModal
  } = useDisclosure();

  const breakpointCols = useBreakpointValue([1, 2, null]);
  const { t } = useTranslation();
  const [limit, setLimit] = useState(2);
  const queryClient = useQueryClient();
  const { slug } = useParams();
  const bg = useColorModeValue('white', 'gray.700');

  const [currentAnswersIndex, setCurrentAnswersIndex] = useState(0);

  const queryParams = [
    ['answers', survey?.id, { offset: currentAnswersIndex }, limit],
    async () => {
      const params = new URLSearchParams({
        collection: survey?.id,
        offset: currentAnswersIndex,
        limit,
        hive: slug
      });
      const { data } = await api.get(
        code
          ? `/cards?${params.toString()}&code=${code}`
          : `/cards?${params.toString()}`
      );
      return data;
    },
    {
      enabled: true
    }
  ];

  const {
    data: currentAnswers,
    status: currentAnswersStatus,
    isSuccess: currentAnswersisSuccess,
    refetch: fetchCurrentAnswers
  } = useQuery(...queryParams);

  const {
    status: previousAnswersStatus,
    isSuccess: previousAnswersisSuccess,
    refetch: fetchPreviousAnswers
  } = useQuery(...queryParams);

  const {
    status: nextAnswersStatus,
    isSuccess: nextAnswersisSuccess,
    refetch: fetchNextAnswers
  } = useQuery(...queryParams);

  useEffect(() => {
    if (limit !== breakpointCols) {
      setLimit(breakpointCols);
    }
    queryClient.removeQueries('survey-answers');
    fetchCurrentAnswers();
    fetchPreviousAnswers();
    fetchNextAnswers();
  }, [
    limit,
    breakpointCols,
    fetchCurrentAnswers,
    fetchPreviousAnswers,
    fetchNextAnswers,
    queryClient
  ]);

  const getLimitedIndexDisplay = index => {
    return Math.min(
      Math.max(parseInt(currentAnswersIndex + 1 + index), 1),
      survey.metrics.cards
    );
  };

  return (
    <Stack
      minHeight="full"
      minWidth="full"
      justifyContent="center"
      alignItems="center"
      position="relative"
      spacing={4}
    >
      <HStack width="full" justifyContent="space-between">
        <HStack spacing={8}>
          <Heading fontSize="md">{field.field}</Heading>
        </HStack>
        <Badge>{t('survey.free_text')}</Badge>
      </HStack>
      <ScrollableContainer
        px={0}
        position="relative"
        minHeight="30vh"
        maxHeight="30vh"
      >
        <LoadingWrapper
          statuses={[
            currentAnswersStatus,
            nextAnswersStatus,
            previousAnswersStatus
          ]}
          errorMessages={[
            t('common.could_not_fetch_data_please_try_again_later', {
              data: t('common.cards').toLowerCase()
            })
          ]}
          indicator={
            <Flex
              my={20}
              height="full"
              width="full"
              alignItems="center"
              justifyContent="center"
            >
              <Spinner size="lg" />
            </Flex>
          }
        >
          {currentAnswersisSuccess &&
          previousAnswersisSuccess &&
          nextAnswersisSuccess ? (
            <Flex width="full">
              <SimpleGrid
                columns={[1, 2, null]}
                spacing={8}
                py={4}
                width="full"
                justifyContent="center"
                alignItems="flex-start"
                minHeight="full"
                minWidth="full"
              >
                {currentAnswers?.results?.map(answer => {
                  return (
                    <Stack
                      key={answer.id}
                      minWidth="full"
                      padding={4}
                      spacing={6}
                      borderWidth="1px"
                      rounded="lg"
                    >
                      <Box minWidth="full" minHeight={12}>
                        <Description>
                          {answer ? answer.description : ''}
                        </Description>
                      </Box>
                    </Stack>
                  );
                })}
              </SimpleGrid>
            </Flex>
          ) : null}
        </LoadingWrapper>
      </ScrollableContainer>
      <HStack spacing={8} justifyContent="flex-end" width="full">
        {survey?.metrics?.cards > 0 && (
          <Text>
            {t('common.showing_amount_out_of_total_items', {
              amount: `${getLimitedIndexDisplay(0)}-${getLimitedIndexDisplay(
                limit - 1
              )}`,
              total: survey.metrics.cards,
              items: t('survey.answers').toLowerCase()
            })}
          </Text>
        )}
        <Button variant="link" onClick={openAllAnswersModal}>
          {t('button.see_all')}
        </Button>
        <HStack>
          <Button
            variant="outline"
            bg={bg}
            isDisabled={
              currentAnswersStatus === 'loading' || currentAnswersIndex - 1 < 0
            }
            onClick={() => {
              setCurrentAnswersIndex(currentAnswersIndex - limit);
              fetchCurrentAnswers();
              fetchPreviousAnswers();
              fetchNextAnswers();
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          <Button
            variant="outline"
            bg={bg}
            isDisabled={
              currentAnswersStatus === 'loading' ||
              currentAnswersIndex + 1 >= survey.metrics.cards - 1
            }
            onClick={() => {
              setCurrentAnswersIndex(currentAnswersIndex + limit);
              fetchCurrentAnswers();
              fetchPreviousAnswers();
              fetchNextAnswers();
            }}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        </HStack>
      </HStack>
      <ModalWrapper
        size="full"
        isOpen={showAllAnswersModal}
        onClose={closeAllAnswersModal}
        title={field.field}
        maxWidth="full"
        sticky
      >
        <AllTextAnswers survey={survey} code={code} title={field.field} />
      </ModalWrapper>
    </Stack>
  );
};

export default TextAnswerCarousel;
