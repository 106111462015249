import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { Divider, Stack, Text, useDisclosure } from '@chakra-ui/react';
import ModalWrapper from 'components/ModalWrapper';
import { isCollectionManager } from 'features/collection/collectionUtils';
import Chart from 'features/chart/Chart';
import api from 'utils/api';
import InputForm from './InputForm';
import MultiSelectForm from './MultiSelectForm';
import SelectAnswer from './SelectAnswer';
import TextAnswer from './TextAnswer';
import VoteResults from './VoteResults';
import {
  findAnswer,
  isAssignee,
  isContributor,
  isProjectOwnerOrMember
} from './cardUtils';

const CardFields = ({
  card,
  fields,
  panel,
  forReport,
  lastField = null,
  panelMember = false
}) => {
  const { t } = useTranslation();

  const {
    isOpen: showEditAnswerModal,
    onOpen: openEditAnswerModal,
    onClose: closeEditAnswerModal
  } = useDisclosure();

  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [selectedField, setSelectedField] = useState(null);

  const queryClient = useQueryClient();
  const { slug } = useParams();
  const { data: profile } = useQuery(['profile', slug], { enabled: false });
  const { data: me } = useQuery('me', { enabled: false });

  const createAnswerMutation = useMutation(
    payload => api.post('/answers', payload),
    {
      onSuccess: ({ data: answer }) => {
        queryClient.setQueryData(['card', card.id], {
          ...card,
          answers: [...card.answers, answer]
        });
        closeEditAnswerModal();
      }
    }
  );

  const createSelectMutation = useMutation(
    payload => api.post(`/cards/${card.id}/select?hive=${slug}`, payload),
    {
      onSuccess: ({ data: card }) => {
        queryClient.setQueryData(['card', card.id], card);
        closeEditAnswerModal();
      }
    }
  );

  const deleteAnswerMutation = useMutation(
    () => api.delete(`/answers/${selectedAnswer.id}`),
    {
      onSuccess: () => {
        queryClient.setQueryData(['card', card.id], {
          ...card,
          answers: card.answers.filter(a => selectedAnswer.id !== a.id)
        });
        closeEditAnswerModal();
      }
    }
  );

  const updateAnswerMutation = useMutation(
    payload => api.patch(`/answers/${payload.id}`, payload),
    {
      onSuccess: ({ data: answer }) => {
        queryClient.setQueryData(['card', card.id], {
          ...card,
          answers: card.answers.map(a => (answer.id === a.id ? answer : a))
        });
        closeEditAnswerModal();
      }
    }
  );

  const handleOnSubmitAnswer = async ({ value }) => {
    if (value) {
      if (selectedAnswer) {
        await updateAnswerMutation.mutateAsync({
          id: selectedAnswer.id,
          card: card.id,
          answer: value
        });
      } else {
        await createAnswerMutation.mutateAsync({
          card: card.id,
          field: selectedField.id,
          answer: value
        });
      }
    } else {
      await deleteAnswerMutation.mutateAsync(selectedAnswer.id);
    }
  };

  const handleOnSubmitSelections = async data => {
    const entries = Object.entries(data);
    let options = [];
    for (let [key, value] of entries) {
      if (Boolean(value)) {
        options.push(parseInt(key));
      }
    }
    await createSelectMutation.mutateAsync({
      field: selectedField.id,
      options
    });
  };

  const canEdit =
    card && me
      ? profile?.is_admin ||
        isAssignee(card, me) ||
        isContributor(card, me) ||
        isCollectionManager(card.collection, me) ||
        panelMember
      : false;

  const renderField = (card, field, forReport) => {
    const isLastField = lastField ? field.id === lastField.id : false;
    if (field.type === 'INPUT') {
      const answer = findAnswer(field, card.answers);
      if (forReport && !answer) {
        return null;
      }
      return (
        <>
          <TextAnswer
            answer={answer}
            field={field}
            onClickEdit={() => {
              setSelectedField(field);
              setSelectedAnswer(findAnswer(field, card.answers));
              openEditAnswerModal();
            }}
            forReport={forReport}
            locked={field.step?.id !== card.step?.id}
            canEdit={canEdit || isProjectOwnerOrMember(card, me)}
          />
          {!isLastField && <Divider />}
        </>
      );
    } else if (field.type === 'MULTI_SELECT') {
      const answer = findAnswer(field, card.selections);
      // TODO: FIX!
      if (!answer) {
        return null;
      }
      return field.many ? (
        <>
          <VoteResults
            field={field}
            options={answer.options}
            cardId={card.id}
            onClickEdit={() => {
              setSelectedField(field);
              openEditAnswerModal();
            }}
            forReport={forReport}
            locked={field.step?.id !== card.step?.id}
          />
          {!isLastField && <Divider />}
        </>
      ) : (
        <>
          <SelectAnswer
            answer={answer}
            field={field}
            onClickEdit={() => {
              setSelectedField(field);
              openEditAnswerModal();
            }}
            forReport={forReport}
            locked={field.step?.id !== card.step?.id}
          />
          {!isLastField && <Divider />}
        </>
      );
    } else if (field.type === 'RATING' && field.chart) {
      return (
        <Chart
          card={card}
          chart={field.chart}
          field={field}
          panel={panel}
          forReport={forReport}
        >
          {!isLastField && <Divider />}
        </Chart>
      );
    }
    return null;
  };

  return fields.length > 0 ? (
    <>
      {fields.map(field => {
        return (
          <Stack key={field.id}>
            {panelMember && (
              <Text variant="muted">{t('card.you_have_been_asked')}</Text>
            )}
            {renderField(card, field, forReport)}
          </Stack>
        );
      })}
      {selectedField && (
        <ModalWrapper
          title={selectedField.field}
          isOpen={showEditAnswerModal}
          onClose={closeEditAnswerModal}
        >
          {selectedField.type === 'INPUT' ? (
            <InputForm
              onSubmit={handleOnSubmitAnswer}
              isOpen={showEditAnswerModal}
              defaultValue={selectedAnswer?.answer}
              helpText={selectedField?.help_text}
            />
          ) : (
            <MultiSelectForm
              answers={findAnswer(selectedField, card.selections)}
              options={selectedField.options}
              onSubmit={handleOnSubmitSelections}
              helpText={selectedField?.help_text}
            />
          )}
        </ModalWrapper>
      )}
    </>
  ) : null;
};

export default CardFields;
