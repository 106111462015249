import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

const UiContext = createContext();

const UiProvider = ({ children }) => {
  const [showMobileCreateButton, setShowMobileCreateButton] = useState(true);
  const [collectionRefetch, setCollectionRefetch] = useState(null);
  const [modalClosureLocked, setModalClosureLocked] = useState(false);
  const [filter, setFilter] = useState({});
  const scrollRef = useRef();
  const { pathname } = useLocation();

  useEffect(() => {
    setFilter({});
  }, [pathname]);

  return (
    <UiContext.Provider
      value={{
        showMobileCreateButton,
        setShowMobileCreateButton,
        collectionRefetch,
        setCollectionRefetch,
        scrollRef,
        modalClosureLocked,
        setModalClosureLocked,
        filter,
        setFilter
      }}
    >
      {children}
    </UiContext.Provider>
  );
};

const useUi = () => useContext(UiContext);

export { UiProvider, useUi };
