import { Button, Flex, Heading, HStack } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import ProgressDots from './ProgressDots';

const StepModalHeader = ({
  currentTitle,
  currentStepIndex,
  setCurrentStepIndex,
  lastStepIndex,
  onClose = {},
  showBackButton = true
}) => {
  return (
    <Flex alignItems="flex-start" justifyContent="space-between">
      <HStack spacing={4}>
        {showBackButton && (
          <Button
            variant="outline"
            onClick={() => {
              currentStepIndex > 0
                ? setCurrentStepIndex(currentStepIndex - 1)
                : onClose();
            }}
          >
            <HStack>
              <FontAwesomeIcon icon={faChevronLeft} />
            </HStack>
          </Button>
        )}
        <Heading
          alignSelf="center"
          noOfLines={3}
          maxWidth="full"
          fontSize={['lg', 'xl', 'xl']}
        >
          {currentTitle}
        </Heading>
      </HStack>
      <ProgressDots
        steps={lastStepIndex + 1}
        currentStepIndex={currentStepIndex}
        pt={4}
      />
    </Flex>
  );
};

export default StepModalHeader;
