import { AsyncSelect, components } from 'chakra-react-select';
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Heading,
  HStack,
  Stack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faUserGroup } from '@fortawesome/pro-regular-svg-icons';
import ModalWrapper from 'components/ModalWrapper';
import MemberOptionLabel from 'features/member/MemberOptionLabel';
import api from 'utils/api';
import { useQuery } from 'react-query';
import { Roles, hasRoleGreaterThanOrEqual } from 'features/member/memberUtils';

const fetchUsers = async (query, slug) => {
  const { data } = await api.get(
    `/users?search=${query}&profiles__hive__slug=${slug}`
  );
  return data;
};

const MembersModal = ({
  code,
  title = i18next.t('common.members'),
  body,
  members,
  isOpen,
  onClose,
  onChangeMembers,
  canEdit,
  canRemoveSelf = true
}) => {
  const { slug } = useParams();
  const [selectedMembers, setSelectedMembers] = useState(members);
  const { t } = useTranslation();
  const { data: me } = useQuery('me', { enabled: false });
  const { data: profile } = useQuery(['profile', slug], { enabled: false });

  useEffect(() => {
    if (me) {
      setSelectedMembers(members);
    }
  }, [members, me]);

  const canRemove = id =>
    canRemoveSelf ||
    hasRoleGreaterThanOrEqual(profile?.role, Roles.MANAGER) ||
    id !== me?.id;

  const MultiValueRemove = props => {
    if (!canRemove(props.data.id)) {
      return null;
    }
    return <components.MultiValueRemove {...props} />;
  };

  const CurrentMembersList = () => (
    <Stack spacing={4} maxHeight="400px" overflowY="auto">
      {members?.map((member, index) => (
        <HStack
          key={`${member?.id}_${index}`}
          as={code ? null : Link}
          to={`/${slug}/member/${member?.id}`}
          maxWidth="fit-content"
          spacing={4}
        >
          <Avatar
            size="sm"
            src={
              member?.avatar
                ? member.avatar
                : member?.third_party_avatar
                ? member.third_party_avatar
                : null
            }
            name={member?.full_name}
          />
          <Heading fontSize="sm" whiteSpace="break-spaces">
            {member.full_name}
          </Heading>
        </HStack>
      ))}
    </Stack>
  );

  return (
    <ModalWrapper
      headerContent={
        <HStack>
          <Text as="span">{title}</Text>
          <Text variant="muted" fontSize="md">{`(${members.length})`}</Text>
        </HStack>
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      <Stack spacing={4}>
        {body && <Text>{body}</Text>}
        {canEdit ? (
          <Tabs size="sm" variant="fresh">
            <TabList>
              <Tab>
                <HStack>
                  <FontAwesomeIcon icon={faUserGroup} />
                  <Text>{title}</Text>
                </HStack>
              </Tab>
              <Tab>
                <HStack>
                  <FontAwesomeIcon icon={faEdit} />
                  <Text>{t('common.edit')}</Text>
                </HStack>
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                {members.length > 0 ? (
                  <CurrentMembersList />
                ) : (
                  <Text variant="muted" textAlign="center">
                    {t('common.no_one_has_been_added_yet')}
                  </Text>
                )}
              </TabPanel>
              <TabPanel>
                <AsyncSelect
                  zIndex={2}
                  value={selectedMembers}
                  isMulti
                  isDisabled={selectedMembers.length !== members.length}
                  isLoading={selectedMembers.length !== members.length}
                  loadOptions={query => fetchUsers(query, slug)}
                  placeholder={t('placeholder.search')}
                  getOptionLabel={member => (
                    <MemberOptionLabel member={member} />
                  )}
                  getOptionValue={member => member.id}
                  onChange={newMembers => {
                    let adjustedMembers = [...newMembers];
                    if (newMembers.length < selectedMembers.length) {
                      if (
                        selectedMembers.find(member => member.id === me?.id)
                      ) {
                        if (!newMembers.find(member => member.id === me?.id)) {
                          if (!canRemove(me?.id)) {
                            adjustedMembers.push(me);
                          }
                        }
                      }
                    }
                    setSelectedMembers(adjustedMembers);
                    onChangeMembers(adjustedMembers);
                  }}
                  isClearable={true}
                  useBasicStyles
                  components={{ MultiValueRemove }}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        ) : members.length > 0 ? (
          <CurrentMembersList />
        ) : (
          <Text variant="muted" textAlign="center">
            {t('common.no_one_has_been_added_yet')}
          </Text>
        )}
      </Stack>
    </ModalWrapper>
  );
};

export default MembersModal;
