import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import {
  Container,
  Flex,
  HStack,
  Heading,
  SimpleGrid,
  Spacer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from '@chakra-ui/react';
import LoadingWrapper from 'components/LoadingWrapper';
import { useUi } from 'providers/UiProvider';
import api from 'utils/api';
import ShareCollection from '../collection/ShareCollection';
import { isCollectionManager } from '../collection/collectionUtils';
import CollectionControls from '../collection/CollectionControls';
import FieldTable from 'features/workflow/FieldTable';
import CardForm from 'features/card/CardForm';
import SurveyAnswerList from './SurveyAnswerList';
import SurveySummary from './SurveySummary';

const SurveyDetailed = () => {
  // const {
  //   isOpen: showConnectProjectModal,
  //   onOpen: openConnectProjectModal,
  //   onClose: closeConnectProjectModal
  // } = useDisclosure();

  // const {
  //   isOpen: showDeleteCollectionModal,
  //   onOpen: openDeleteCollectionModal,
  //   onClose: closeDeleteCollectionModal
  // } = useDisclosure();

  // const {
  //   isOpen: showExportModal,
  //   onOpen: openExportModal,
  //   onClose: closeExportModal
  // } = useDisclosure();

  // const { isOpen: showSidebar, onToggle: toggleSidebar } = useDisclosure({
  //   defaultIsOpen: window.innerWidth >= 1024
  // });

  const [tabIndex, setTabIndex] = useState(0);
  const [subTabIndex, setSubTabIndex] = useState(0);
  const { slug } = useParams();
  const { data: hive } = useQuery(['hive', slug]);
  const { data: profile } = useQuery(['profile', slug]);
  const { setCollectionRefetch, setShowMobileCreateButton } = useUi();
  //const [searchParams, setSearchParams] = useSearchParams();
  // const [selectedView, setSelectedView] = useState(
  //   searchParams.get('step') ? 'step' : 'collection'
  // );
  const { scrollRef } = useUi();
  const { update } = useIntercom();
  const { t } = useTranslation();
  const { surveyId } = useParams();
  // const { filter, setFilter } = useUi();
  const { data: me } = useQuery('me');
  //const queryClient = useQueryClient();
  // const navigate = useNavigate();
  // const toast = useToast();

  // useEffect(() => {
  //   if (Object.keys(filter).length < 1) {
  //     setFilter({
  //       ...filter,
  //       collection: surveyId,
  //       step: searchParams.get('step')
  //         ? parseInt(searchParams.get('step'))
  //         : undefined
  //     });
  //   }
  // }, [filter, setFilter, searchParams, surveyId]);

  useEffect(() => {
    return () => {
      setCollectionRefetch(null);
      setShowMobileCreateButton(true);
    };
  }, [setCollectionRefetch, setShowMobileCreateButton]);

  // const { data: invites, refetch: inviteRefetch } = useQuery(
  //   ['invites', surveyId],
  //   async () => {
  //     const { data: invites } = await api.get(
  //       `/collections/invites?collection=${surveyId}?hive__slug=${slug}`
  //     );
  //     return invites;
  //   }
  // );

  //OLD, borrow new version that has slug included instead!
  // const deleteCollectionMutation = useMutation(
  //   () => api.delete(`/collections/${surveyId}?hive__slug=${slug}`),
  //   {
  //     onSuccess: () => {
  //       const hive = queryClient.getQueryData(['hive', collection.hive.slug]);
  //       queryClient.setQueryData(['hive', collection.hive.slug], {
  //         ...hive,
  //         metrics: {
  //           ...hive.metrics,
  //           collections: hive.metrics.collections - 1
  //         }
  //       });
  //       closeDeleteCollectionModal();
  //       toast({
  //         title: 'Collection was successfully deleted.',
  //         status: 'success',
  //         position: 'bottom-right',
  //         isClosable: true
  //       });
  //       navigate(-1);
  //     }
  //   }
  // );

  //OLD, borrow new version that has slug included instead!
  // const updateCollectionMutation = useMutation(
  //   collection =>
  //     api.patch(`/collections/${surveyId}?hive__slug=${slug}`, collection, { timeout: 0 }),
  //   {
  //     onSuccess: ({ data }) => {
  //       queryClient.setQueryData(['collection', surveyId], data);
  //       toast({
  //         title: 'Collection was successfully updated.',
  //         status: 'success',
  //         position: 'bottom-right',
  //         isClosable: true
  //       });
  //     }
  //   }
  // );

  const {
    status,
    data: survey,
    isSuccess,
    refetch
  } = useQuery(
    ['survey', surveyId],
    async () => {
      const { data } = await api.get(
        `/collections/${surveyId}?hive__slug=${slug}`
      );
      return data;
    },
    {
      onSuccess: collection => {
        setShowMobileCreateButton(collection.open);
        setCollectionRefetch(() => refetch);
        const collectionManager = isCollectionManager(collection, me);
        update({
          customAttributes: {
            collection_manager: collectionManager
          }
        });
      },
      onError: () => {
        update({
          customAttributes: {
            collection_manager: false
          }
        });
      }
    }
  );

  // const refreshCards = (card, step) => {
  //   refetch();
  //   const isAll = filter.step === null;
  //   if (!isAll) {
  //     let shouldRemoveCard = false;
  //     if (card?.step?.id !== step) {
  //       shouldRemoveCard = true;
  //     }
  //     const cards = queryClient.getQueryData([
  //   'cards',
  //   ...cardFilterToArray({...filter})
  // ]);
  //     if (card) {
  //       if (shouldRemoveCard) {
  //         const pages = cards.pages.map(page => ({
  //           ...page,
  //           count: page.count - 1,
  //           results: page.results.filter(c => c.id !== card.id)
  //         }));
  //         queryClient.setQueryData([
  //   'cards',
  //   ...cardFilterToArray({...filter})
  // ], { ...cards, pages });
  //       } else {
  //         const pages = cards.pages.map(page => {
  //           return {
  //             ...page,
  //             count: page.count + 1
  //           };
  //         });
  //         if (pages.length > 0) {
  //           pages[0].results = [card, ...pages[0].results];
  //         }
  //         queryClient.setQueryData([
  //   'cards',
  //   ...cardFilterToArray({...filter})
  // ], { ...cards, pages });
  //       }
  //     }
  //   }
  // };

  // const {
  //   isOpen: showEditCollectionModal,
  //   onOpen: openEditCollectionModal,
  //   onClose: closeEditCollectionModal
  // } = useDisclosure();

  // const {
  //   isOpen: showPublishCollectionModal,
  //   onOpen: openPublishCollectionModal,
  //   onClose: closePublishCollectionModal
  // } = useDisclosure();

  // const {
  //   isOpen: showShareCollectionModal,
  //   onOpen: openShareCollectionModal,
  //   onClose: closeShareCollectionModal
  // } = useDisclosure();

  // const {
  //   isOpen: showCollectionManagersModal,
  //   onOpen: openCollectionManagersModal,
  //   onClose: closeCollectionManagersModal
  // } = useDisclosure();

  // const {
  //   isOpen: showCollectionWorkflowModal,
  //   onOpen: openCollectionWorkflowModal,
  //   onClose: closeCollectionWorkflowModal
  // } = useDisclosure();

  // const handleOnLinkCreated = data => {
  //   queryClient.setQueryData(['collection', surveyId], data);
  // };

  // const handleOnLinkDeleted = data => {
  //   queryClient.setQueryData(['collection', surveyId], data);
  // };

  // const handleOnSocialMediaChange = data => {
  //   queryClient.setQueryData(['collection', surveyId], data);
  // };

  //OLD, borrow new version that has slug included instead!

  // const handleOnChangeBoard = async board => {
  //   await updateCollectionMutation.mutateAsync({
  //     board
  //   });
  // };

  //OLD, borrow new version that has slug included instead!

  // const handleOnChangeManagers = async members => {
  //   const managers = members.map(member => member.id);
  //   await updateCollectionMutation.mutateAsync({
  //     managers
  //   });
  // };

  //OLD, borrow new version that has slug included instead!

  // const handleOnChangeProject = async project => {
  //   await updateCollectionMutation.mutateAsync({
  //     project: project ? project.id : null
  //   });
  // };

  //OLD, borrow new version that has slug included instead!

  // const handleOnDeleteCollection = async () => {
  //   await deleteCollectionMutation.mutateAsync();
  // };

  //OLD, borrow new version that has slug included instead!

  // const handleOnUpdateCollection = async collection => {
  //   const payload = new FormData();
  //   if (!collection.image || collection.image instanceof File) {
  //     payload.append('image', collection.image ? collection.image : '');
  //   }
  //   if (!collection.video) {
  //     payload.append('video', '');
  //   }
  //   payload.append('unsplash', collection.unsplash ? collection.unsplash : '');
  //   payload.append(
  //     'workflow',
  //     collection.workflow ? collection.workflow.id : ''
  //   );
  //   payload.append('title', collection.title);
  //   payload.append('description', collection.description);
  //   payload.append('hide_comments', collection.hide_comments);
  //   try {
  //     if (collection.video instanceof File) {
  //       const { data } = await api.post('/s3/generate-presigned-url', {
  //         filename: collection.video.name
  //       });
  //       await axios.put(data.url, collection.video, {
  //         headers: { 'x-amz-acl': 'public-read' }
  //       });
  //       payload.append('video', data.key);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   await updateCollectionMutation.mutateAsync(payload);
  // };

  // const handleOnClickCard = card => {
  //   if (card.id) {
  //     setSearchParams({ card: card.id });
  //   }
  // };

  //OLD, borrow new version that has slug included instead!

  // const handleOnClickOpen = async () => {
  //   await updateCollectionMutation.mutateAsync({
  //     open: !collection.open
  //   });
  // };

  const isAdminOrCollectionManager =
    profile?.is_admin || isCollectionManager(survey, me);

  return (
    <>
      <LoadingWrapper
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.collection').toLowerCase()
          })
        ]}
      >
        {isSuccess && (
          <Flex ref={scrollRef} overflowY="scroll" height="calc(100vh - 70px)">
            <Container maxW="container.lg" marginY={8}>
              <Stack spacing={8}>
                <HStack spacing={4}>
                  <Heading fontSize="3xl">{t('common.survey')}</Heading>
                  <Spacer />
                  {isAdminOrCollectionManager && (
                    <CollectionControls
                      collection={survey}
                      // onClickConnectProject={openConnectProjectModal}
                      // onClickDelete={openDeleteCollectionModal}
                      // onClickEdit={openEditCollectionModal}
                      // onClickExport={openExportModal}
                      // onClickManagers={openCollectionManagersModal}
                      // onClickPublish={openPublishCollectionModal}
                      // onClickShare={openShareCollectionModal}
                    />
                  )}
                </HStack>
                <Tabs
                  size="sm"
                  variant="fresh"
                  index={tabIndex}
                  onChange={tabIndex => {
                    setTabIndex(tabIndex);
                  }}
                >
                  <SimpleGrid
                    templateColumns={['1fr', null, '1fr 200px']}
                    gap={4}
                    overflowX="auto"
                  >
                    <TabList>
                      <Tab>{t('survey.build')}</Tab>
                      <Tab>{t('survey.preview')}</Tab>
                      {hive.use_share && <Tab>{t('common.share')}</Tab>}
                      <Tab>{t('survey.results')}</Tab>
                    </TabList>
                  </SimpleGrid>
                  <TabPanels>
                    <TabPanel>
                      <FieldTable step={{ id: 1280 }} workflow={{ id: 463 }} />
                    </TabPanel>
                    <TabPanel>
                      {/* <Container> */}
                      <CardForm
                        collection={{ id: surveyId }}
                        workflowId={463}
                        useDescription={false}
                        useImage={false}
                      />
                      {/* </Container> */}
                    </TabPanel>
                    {hive.use_share && (
                      <TabPanel>
                        <ShareCollection
                          collection={{ id: surveyId }}
                          //onLinkCreated={handleOnLinkCreated}
                          //onLinkDeleted={handleOnLinkDeleted}
                          //onInviteChange={inviteRefetch}
                          //onShareSocialMediaChange={handleOnSocialMediaChange}
                        />
                      </TabPanel>
                    )}
                    <TabPanel>
                      <Tabs
                        size="sm"
                        variant="fresh"
                        index={subTabIndex}
                        onChange={subTabIndex => {
                          setSubTabIndex(subTabIndex);
                        }}
                      >
                        <SimpleGrid
                          templateColumns={['1fr', null, '1fr 200px']}
                          gap={4}
                          overflowX="auto"
                        >
                          <TabList>
                            <Tab>{t('survey.answers')}</Tab>
                            <Tab>{t('survey.summary')}</Tab>
                          </TabList>
                        </SimpleGrid>
                        <TabPanels my={4}>
                          <TabPanel>
                            <SurveyAnswerList
                              filter={{ collection: survey.id }}
                            />
                          </TabPanel>
                          <TabPanel>
                            <SurveySummary survey={survey} />
                          </TabPanel>
                        </TabPanels>
                      </Tabs>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Stack>
            </Container>
          </Flex>
        )}
      </LoadingWrapper>
    </>
  );
};

export default SurveyDetailed;
