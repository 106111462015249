import { Center, HStack, Text } from '@chakra-ui/react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NewItem = ({ text, minHeight = '100px', onClick }) => (
  <Center
    boxSizing="content-box"
    cursor="pointer"
    padding={5}
    spacing={4}
    rounded="lg"
    borderWidth="4px"
    borderStyle="dotted"
    minHeight={minHeight}
    onClick={onClick}
  >
    <HStack>
      <FontAwesomeIcon icon={faPlus} />
      <Text>{text}</Text>
    </HStack>
  </Center>
);

export default NewItem;
