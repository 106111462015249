import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { Box, SimpleGrid, Stack, useDisclosure } from '@chakra-ui/react';
import InfiniteScrollHelper from 'components/InfiniteScrollHelper';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingWrapper';
import NewItem from 'components/NewItem';
import UpgradeModal from 'components/UpgradeModal';
import { hasPlan, Plans } from 'features/hive/hiveUtils';
import api from 'utils/api';
import CollectionListItem from './CollectionListItem';
import { appendCollectionPropsToURLParams } from './collectionUtils';

const CollectionList = ({
  itemOnClick,
  open,
  boardId,
  managerId,
  projectId,
  stepId,
  workflowId,
  noStep,
  search,
  columns = [1, null, 2],
  openCreateCollectionModal,
  ...rest
}) => {
  const { slug } = useParams();
  const { data: profile } = useQuery(['profile', slug]);

  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const {
    status,
    data: collections,
    isSuccess,
    fetchNextPage,
    isFetching,
    hasNextPage,
    refetch: fetchCollections
  } = useInfiniteQuery(
    [
      'collections',
      { hive__slug: slug },
      open,
      boardId,
      managerId,
      projectId,
      stepId,
      workflowId,
      noStep
    ],
    async ({ pageParam = 0 }) => {
      const params = new URLSearchParams({
        offset: pageParam,
        limit: 20
      });
      params.append('hive__slug', slug);
      appendCollectionPropsToURLParams(
        {
          open,
          boardId,
          managerId,
          projectId,
          stepId,
          workflowId,
          noStep,
          search
        },
        params
      );
      const { data } = await api.get(`/collections?${params.toString()}`);
      return data;
    },
    {
      getNextPageParam: (lastPage, _) => {
        if (lastPage.next) {
          var url = new URL(lastPage.next);
          var offset = url.searchParams.get('offset');
          return offset;
        }
        return null;
      }
    }
  );

  const {
    isOpen: showUpgradeModal,
    onOpen: openUpgradeModal,
    onClose: closeUpgradeModal
  } = useDisclosure();

  useEffect(() => {
    fetchCollections();
  }, [fetchCollections, search, stepId]);

  return (
    <Box>
      <LoadingWrapper
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.collections').toLowerCase()
          })
        ]}
      >
        {isSuccess ? (
          <Stack>
            <InfiniteScrollHelper
              hasMore={!isFetching && hasNextPage}
              loadMore={fetchNextPage}
              scrollParent={rest?.scrollParent}
            >
              <SimpleGrid columns={columns} spacing={6}>
                {profile?.is_admin_or_manager && openCreateCollectionModal && (
                  <NewItem
                    text={t('button.new_collection')}
                    onClick={() => {
                      const hive = queryClient.getQueryData(['hive', slug]);
                      if (hive) {
                        if (
                          hasPlan(hive?.plan, Plans.Freemium) &&
                          hive.metrics.collections >= 2
                        ) {
                          openUpgradeModal();
                        } else {
                          openCreateCollectionModal();
                        }
                      }
                    }}
                  />
                )}
                {collections.pages[0].count > 0 &&
                  collections.pages.map(page =>
                    page.results.map(collection => (
                      <CollectionListItem
                        key={collection.id}
                        collection={collection}
                        onClick={
                          itemOnClick
                            ? () => {
                                itemOnClick(collection);
                              }
                            : null
                        }
                      />
                    ))
                  )}
              </SimpleGrid>
              {isFetching && <LoadingIndicator />}
            </InfiniteScrollHelper>
            <UpgradeModal
              isOpen={showUpgradeModal}
              onClose={closeUpgradeModal}
            />
          </Stack>
        ) : null}
      </LoadingWrapper>
    </Box>
  );
};

export default CollectionList;
