import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Divider,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Show,
  Text
} from '@chakra-ui/react';
import {
  faArrowRightArrowLeft,
  faBell,
  faCog,
  faLifeRing,
  faMessageLines,
  faShield,
  faSignOut,
  faUser
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MemberAvatar from 'components/MemberAvatar';
import NotificationBadge from 'features/notification/NotificationBadge';
import { useAuth } from 'providers/AuthProvider';

const NavigationMenu = ({ me, onClickNotifications }) => {
  const { logout } = useAuth();
  const { slug } = useParams();
  const { data: profile } = useQuery(['profile', slug]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleOnClickSignOut = () => {
    logout();
    navigate(`/${slug}/login`);
  };

  const handleOnClickChangeHive = () => {
    localStorage.removeItem('slug');
    navigate('/join');
  };

  return me ? (
    <Menu placement="bottom-end">
      <MenuButton>
        <MemberAvatar member={me} size="sm" width="40px" height="40px" />
        <Show below="sm">
          <NotificationBadge
            unreadNotifications={profile.unread_notifications}
            offset={0.5}
          />
        </Show>
      </MenuButton>
      <MenuList>
        <Show below="sm">
          <MenuItem
            onClick={onClickNotifications}
            icon={<FontAwesomeIcon icon={faBell} fixedWidth />}
          >
            <HStack justifyContent="flex-start" spacing={4}>
              <Text>{t('common.notifications')}</Text>
              <NotificationBadge
                unreadNotifications={profile.unread_notifications}
                position="static"
              />
            </HStack>
          </MenuItem>
        </Show>
        <MenuItem
          as={Link}
          to={`/${slug}/member/${me.id}`}
          icon={<FontAwesomeIcon icon={faUser} fixedWidth />}
        >
          <Text>{t('common.profile')}</Text>
        </MenuItem>
        <MenuItem
          as={Link}
          to={`/${slug}/settings`}
          icon={<FontAwesomeIcon icon={faCog} fixedWidth />}
        >
          <Text>{t('common.settings')}</Text>
        </MenuItem>
        <Divider />
        {profile?.is_admin_or_manager && (
          <>
            {profile.is_admin && (
              <MenuItem
                as={Link}
                to={`/${slug}/admin`}
                icon={<FontAwesomeIcon icon={faShield} fixedWidth />}
              >
                <Text>{t('common.admin')}</Text>
              </MenuItem>
            )}
            <MenuItem
              as="a"
              target="_blank"
              href="https://help.hives.co/en/"
              icon={<FontAwesomeIcon icon={faLifeRing} fixedWidth />}
            >
              <Text>{t('common.help_center')}</Text>
            </MenuItem>
          </>
        )}
        <MenuItem
          as="a"
          target="_blank"
          href="https://app.hives.co/shared/collection/1f00f5da-2e19-4b3d-ac6c-d46538b17c7d"
          icon={<FontAwesomeIcon icon={faMessageLines} fixedWidth />}
        >
          <Text>{t('common.feedback')}</Text>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={handleOnClickChangeHive}
          icon={<FontAwesomeIcon icon={faArrowRightArrowLeft} fixedWidth />}
        >
          <Text>{t('common.change_hive')}</Text>
        </MenuItem>
        <MenuItem
          onClick={handleOnClickSignOut}
          icon={<FontAwesomeIcon icon={faSignOut} fixedWidth />}
        >
          <Text>{t('common.sign_out')}</Text>
        </MenuItem>
      </MenuList>
    </Menu>
  ) : null;
};

export default NavigationMenu;
