import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Text
} from '@chakra-ui/react';
import ChartResult from 'features/chart/ChartResult';
import api from 'utils/api';

const AverageScore = ({ collectionId, workflowId, stepId }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const {
    data: fields,
    isSuccess,
    refetch: fetchCharts
  } = useQuery(
    ['charts', workflowId],
    async () => {
      const params = new URLSearchParams();
      if (workflowId) {
        params.append('workflow', workflowId);
      }
      if (collectionId) {
        params.append('collection', collectionId);
      }
      params.append('type', 'RATING');
      const { data } = await api.get(`/fields?${params.toString()}`);
      return data;
    },
    {
      enabled: false
    }
  );

  useEffect(() => {
    queryClient.removeQueries('charts');
    fetchCharts();
  }, [fetchCharts, queryClient, collectionId, workflowId, stepId]);

  return isSuccess ? (
    <>
      {fields.length > 0 ? (
        <Tabs
          size="sm"
          variant="fresh"
          index={tabIndex}
          onChange={tabIndex => {
            setTabIndex(tabIndex);
          }}
        >
          <TabList>
            {fields?.map(field => (
              <Tab whiteSpace="nowrap" key={field.id}>
                {field.field}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {fields?.map(field => (
              <TabPanel key={field.id} py={4}>
                <ChartResult
                  chart={field.chart}
                  collectionId={collectionId}
                  stepId={stepId}
                  workflowId={workflowId}
                />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      ) : (
        <Text variant="muted">{t('common.nothing_here_yet')}</Text>
      )}
    </>
  ) : null;
};
export default AverageScore;
