import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay
} from '@chakra-ui/react';
import CardList from './CardList';

const CardDrawer = ({ filter, header, onClose, isOpen }) => {
  return (
    <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        {header && <DrawerHeader>{header}</DrawerHeader>}
        <DrawerCloseButton />
        <DrawerBody>
          <CardList
            filter={filter}
            breakpointCols={{
              default: 1
            }}
          />
        </DrawerBody>
        <DrawerFooter />
      </DrawerContent>
    </Drawer>
  );
};

export default CardDrawer;
