import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useLocation, useParams } from 'react-router-dom';
import {
  Button,
  Container,
  Flex,
  HStack,
  Image,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faSearch } from '@fortawesome/pro-regular-svg-icons';
import CreateButton from 'components/CreateButton';
import NotificationBadge from 'features/notification/NotificationBadge';
import NotificationDrawer from 'features/notification/NotificationDrawer';
import { hasPlanGreaterThanOrEqual, Plans } from 'features/hive/hiveUtils';
import NavigationMenu from './NavigationMenu';

const DesktopHeader = () => {
  const { slug } = useParams();
  const { data: me } = useQuery('me');
  const { data: profile } = useQuery(['profile', slug]);
  const { data: hive } = useQuery(['hive', slug]);

  const { t } = useTranslation();

  const { pathname } = useLocation();

  const {
    isOpen: showNotificationDrawer,
    onOpen: openNotificationDrawer,
    onClose: closeNotificationDrawer
  } = useDisclosure();

  const logo = useColorModeValue(
    hive.dark_logo ? hive.dark_logo : '/hives_dark.png',
    hive.light_logo ? hive.light_logo : '/hives_light.png'
  );

  return (
    <>
      <Flex
        alignItems="center"
        borderBottomWidth={1}
        height="70px"
        bg={useColorModeValue('white', 'gray.800')}
      >
        <Container maxW="container.lg">
          <Flex alignItems="center" justifyContent="space-between">
            <Flex flexBasis={0} flexGrow={1}>
              {me && (
                <Link to={`/${slug}`}>
                  <Image src={logo} height={8} />
                </Link>
              )}
            </Flex>
            {hasPlanGreaterThanOrEqual(hive?.plan, Plans.Starter) && (
              <HStack spacing={8} display={['none', null, 'flex']}>
                <Button
                  as={Link}
                  to={`/${slug}`}
                  variant="link"
                  textDecoration={pathname === '/' ? 'underline' : 'none'}
                >
                  {t('common.home')}
                </Button>
                {hive.use_feed && (
                  <Button
                    as={Link}
                    to={`/${slug}/feed`}
                    variant="link"
                    textDecoration={pathname === '/feed' ? 'underline' : 'none'}
                  >
                    {t('common.feed')}
                  </Button>
                )}
                {profile?.is_admin_or_manager && (
                  <>
                    {hasPlanGreaterThanOrEqual(
                      hive?.plan,
                      Plans.Professional
                    ) && (
                      <Button
                        as={Link}
                        to={`/${slug}/channels`}
                        variant="link"
                        textDecoration={
                          pathname.startsWith('/channel') ? 'underline' : 'none'
                        }
                      >
                        {t('common.channels')}
                      </Button>
                    )}
                    <Button
                      as={Link}
                      to={`/${slug}/dashboard`}
                      variant="link"
                      textDecoration={
                        pathname === '/dashboard' ? 'underline' : 'none'
                      }
                    >
                      {t('common.dashboard')}
                    </Button>
                    <Button
                      as={Link}
                      to={`/${slug}/collections`}
                      variant="link"
                      textDecoration={
                        pathname === '/collections' ? 'underline' : 'none'
                      }
                    >
                      {t('common.my_collections')}
                    </Button>
                  </>
                )}
              </HStack>
            )}
            <Flex flexBasis={0} flexGrow={1} justifyContent="flex-end">
              <HStack>
                <CreateButton />
                <Link to={`/${slug}/search`}>
                  <Button variant="outline">
                    <FontAwesomeIcon icon={faSearch} />
                  </Button>
                </Link>
                <Button
                  position="relative"
                  variant="outline"
                  onClick={openNotificationDrawer}
                >
                  <FontAwesomeIcon icon={faBell} />
                  <NotificationBadge
                    unreadNotifications={profile.unread_notifications}
                  />
                </Button>
                <NavigationMenu me={me} />
              </HStack>
            </Flex>
          </Flex>
        </Container>
      </Flex>
      <NotificationDrawer
        isOpen={showNotificationDrawer}
        onClose={closeNotificationDrawer}
      />
    </>
  );
};

export default DesktopHeader;
