import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormHelperText,
  HStack,
  Stack,
  Text
} from '@chakra-ui/react';

const MultiSelectForm = ({ answers, options, helpText, onSubmit }) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm({
    mode: 'onChange'
  });

  const checkIfChecked = option => {
    if (answers?.field?.options) {
      const result = answers.field.options.find(o => o.id === option.id);
      if (result) {
        return result.selected;
      }
    }
    return false;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <FormControl>
          <Stack spacing={4}>
            {options.map(option => (
              <Controller
                key={option.id}
                control={control}
                defaultValue={checkIfChecked(option)}
                name={`${option.id}`}
                render={({ field: { onChange, value } }) => (
                  <HStack key={option.id} spacing={2} alignItems="flex-start">
                    <Checkbox
                      defaultChecked={value}
                      onChange={onChange}
                      pt={1}
                    />
                    <Text>{option.option}</Text>
                  </HStack>
                )}
              />
            ))}
            <FormControl>
              {helpText && <FormHelperText>{helpText}</FormHelperText>}
            </FormControl>
          </Stack>
        </FormControl>
        <Flex justifyContent="flex-end">
          <Button type="submit" colorScheme="teal" isLoading={isSubmitting}>
            {t('button.save')}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};

export default MultiSelectForm;
