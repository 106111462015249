import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import {
  Button,
  Container,
  Flex,
  HStack,
  Image,
  Show,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBell } from '@fortawesome/pro-regular-svg-icons';
import NavigationDrawer from './NavigationDrawer';
import NavigationMenu from './NavigationMenu';
import { hasPlanGreaterThanOrEqual, Plans } from 'features/hive/hiveUtils';
import NotificationDrawer from 'features/notification/NotificationDrawer';
import NotificationBadge from 'features/notification/NotificationBadge';

const MobileHeader = () => {
  const { slug } = useParams();
  const { data: me } = useQuery('me');
  const { data: profile } = useQuery(['profile', slug]);
  const { data: hive } = useQuery(['hive', slug]);

  const {
    isOpen: showNavigationDrawer,
    onOpen: openNavigationDrawer,
    onClose: closeNavigationDrawer
  } = useDisclosure();

  const {
    isOpen: showNotificationDrawer,
    onOpen: openNotificationDrawer,
    onClose: closeNotificationDrawer
  } = useDisclosure();

  const logo = useColorModeValue(
    hive?.dark_logo ? hive.dark_logo : '/hives_dark.png',
    hive?.light_logo ? hive.light_logo : '/hives_light.png'
  );

  return (
    <>
      <Flex
        borderBottomWidth={1}
        position="sticky"
        py={4}
        top={0}
        bg={useColorModeValue('white', 'gray.800')}
        zIndex={1}
      >
        <Container maxW="container.lg">
          <Flex alignItems="center" justifyContent="space-between">
            {hasPlanGreaterThanOrEqual(hive?.plan, Plans.Starter) && (
              <Flex flexBasis={0} flexGrow={1}>
                <Button variant="outline" onClick={openNavigationDrawer}>
                  <FontAwesomeIcon icon={faBars} />
                </Button>
              </Flex>
            )}
            <Flex>
              <Link to={`/${slug}`}>
                <Image src={logo} height={8} />
              </Link>
            </Flex>
            <Flex flexBasis={0} flexGrow={1} justifyContent="flex-end">
              <HStack>
                <Show above="sm">
                  <Button
                    position="relative"
                    variant="outline"
                    onClick={openNotificationDrawer}
                  >
                    <FontAwesomeIcon icon={faBell} />
                    <NotificationBadge
                      unreadNotifications={profile.unread_notifications}
                    />
                  </Button>
                </Show>
                <NavigationMenu
                  me={me}
                  onClickNotifications={openNotificationDrawer}
                />
              </HStack>
            </Flex>
          </Flex>
        </Container>
        <NavigationDrawer
          logo={logo}
          isOpen={showNavigationDrawer}
          onClose={closeNavigationDrawer}
        />
      </Flex>
      <NotificationDrawer
        isOpen={showNotificationDrawer}
        onClose={closeNotificationDrawer}
      />
    </>
  );
};

export default MobileHeader;
