import axios from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query';
import { useParams } from 'react-router-dom';
import {
  Button,
  Container,
  Flex,
  Heading,
  HStack,
  Stack,
  useToast
} from '@chakra-ui/react';
import InfiniteScrollHelper from 'components/InfiniteScrollHelper';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingWrapper';
import AnnouncementDetailed from './AnnouncementDetailed';
import AnnouncementListItem from './AnnouncementListItem';
import AnnouncementForm from './AnnouncementForm';
import ModalWrapper from 'components/ModalWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import api from 'utils/api';

const AnnouncementList = () => {
  const { slug } = useParams();
  const { data: profile } = useQuery(['profile', slug]);
  const { data: hive } = useQuery(['hive', slug]);

  const [showAnnouncementModal, setShowAnnouncementModal] = useState(false);
  const [showCreateAnnouncementModal, setShowCreateAnnouncementModal] =
    useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);

  const { t } = useTranslation();
  const toast = useToast();

  const queryClient = useQueryClient();

  const {
    status,
    data: announcements,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isSuccess
  } = useInfiniteQuery(
    'announcements',
    async ({ pageParam = 0 }) => {
      const { data } = await api.get(
        `/announcements?offset=${pageParam}&limit=10`
      );
      return data;
    },
    {
      getNextPageParam: (lastPage, _) => {
        if (lastPage.next) {
          var url = new URL(lastPage.next);
          var offset = url.searchParams.get('offset');
          return offset;
        }
        return null;
      }
    }
  );

  const createAnnouncementMutation = useMutation(
    async announcement =>
      await api.post('/announcements', announcement, { timeout: 0 }),
    {
      onSuccess: ({ data: announcement }) => {
        const announcements = queryClient.getQueryData('announcements');
        if (announcements && announcements.pages) {
          const pages = announcements.pages.map(page => {
            return {
              ...page,
              count: page.count + 1
            };
          });
          if (pages.length > 0) {
            pages[0].results = [announcement, ...pages[0].results];
          }
          queryClient.setQueryData('announcements', {
            ...announcements,
            pages
          });
        }
        toggleShowCreateAnnouncementModal();
        toast({
          title: 'Announcement was successfully created.',
          status: 'success',
          position: 'bottom-right',
          isClosable: true
        });
      }
    }
  );

  const toggleShowAnnouncementModal = () => {
    setShowAnnouncementModal(!showAnnouncementModal);
  };

  const toggleShowCreateAnnouncementModal = () => {
    setShowCreateAnnouncementModal(!showCreateAnnouncementModal);
  };

  const handleOnCreateAnnouncement = async announcement => {
    const payload = new FormData();
    payload.append('hive', hive.id);
    payload.append('image', announcement.image ? announcement.image : '');
    payload.append(
      'unsplash',
      announcement.unsplash ? announcement.unsplash : ''
    );
    payload.append('title', announcement.title);
    payload.append('description', announcement.description);
    try {
      if (announcement.video instanceof File) {
        const { data } = await api.post('/s3/generate-presigned-url', {
          filename: announcement.video.name
        });
        await axios.put(data.url, announcement.video, {
          headers: { 'x-amz-acl': 'public-read' }
        });
        payload.append('video', data.key);
      }
    } catch (e) {
      console.log(e);
    }
    await createAnnouncementMutation.mutateAsync(payload);
  };

  return (
    <Container maxW="container.lg" marginY={8}>
      <Stack spacing={8}>
        <Flex alignItems="center" justifyContent="space-between">
          <Heading fontSize="2xl">{t('common.announcements')}</Heading>
          {profile?.is_admin_or_manager && (
            <Button
              colorScheme="teal"
              onClick={toggleShowCreateAnnouncementModal}
            >
              <HStack>
                <FontAwesomeIcon icon={faPlus} />
              </HStack>
            </Button>
          )}
        </Flex>
        <LoadingWrapper
          statuses={[status]}
          errorMessages={[
            t('common.could_not_fetch_data_please_try_again_later', {
              data: t('common.announcements').toLowerCase()
            })
          ]}
        >
          {isSuccess ? (
            <>
              <InfiniteScrollHelper
                hasMore={!isFetching && hasNextPage}
                loadMore={fetchNextPage}
              >
                <Stack spacing={4}>
                  {announcements.pages.map(page =>
                    page.results.map(announcement => (
                      <AnnouncementListItem
                        key={announcement.id}
                        announcement={announcement}
                        onClickReadMore={() => {
                          setSelectedAnnouncement(announcement);
                          toggleShowAnnouncementModal();
                        }}
                      />
                    ))
                  )}
                  {isFetching && <LoadingIndicator />}
                </Stack>
              </InfiniteScrollHelper>
              {selectedAnnouncement && (
                <AnnouncementDetailed
                  announcement={selectedAnnouncement}
                  canEdit={profile?.is_admin_or_manager}
                  isOpen={showAnnouncementModal}
                  onClose={toggleShowAnnouncementModal}
                />
              )}
              <ModalWrapper
                title={t('home.create_announcement')}
                size="full"
                isOpen={showCreateAnnouncementModal}
                onClose={toggleShowCreateAnnouncementModal}
              >
                <AnnouncementForm
                  isOpen={showCreateAnnouncementModal}
                  onSubmit={handleOnCreateAnnouncement}
                />
              </ModalWrapper>
            </>
          ) : null}
        </LoadingWrapper>
      </Stack>
    </Container>
  );
};

export default AnnouncementList;
