import { Flex, Stack } from '@chakra-ui/react';
import TextAnswerCarousel from './TextAnswerCarousel';
import React from 'react';
import VoteResults from 'features/card/VoteResults';
import BoxContainer from 'components/BoxContainer';

const SurveySummary = ({ survey }) => {
  const fields = survey?.workflow?.fields; //getSurveyFields();

  const renderField = field => {
    if (field.type === 'INPUT') {
      return (
        <Flex borderWidth="1px" rounded="lg" padding={8} width="full">
          <TextAnswerCarousel survey={survey} field={field} />
        </Flex>
      );
    } else if (field.type === 'MULTI_SELECT') {
      return (
        <BoxContainer padding={8}>
          <VoteResults field={field} options={field?.options} forReport />
        </BoxContainer>
      );
    }
    return null;
  };

  return (
    <Stack spacing={8}>
      {fields &&
        fields.map(field => {
          const renderedField = renderField(field);
          if (renderedField) {
            return (
              <React.Fragment key={field.id}>{renderedField}</React.Fragment>
            );
          }
          return null;
        })}
    </Stack>
  );
};

export default SurveySummary;
