import { useTranslation } from 'react-i18next';
import { Box, Button, HStack, Heading, Stack, Tooltip } from '@chakra-ui/react';
import Description from 'components/Description';
import Answerer from './Answerer';
import AccessBadge from 'components/AccessBadge';

const TextAnswer = ({
  answer,
  canEdit,
  field,
  onClickEdit,
  forReport,
  locked
}) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={6}>
      <HStack alignItems="center" justifyContent="space-between">
        <Heading fontSize="md">{field.field}</Heading>
        {canEdit && !forReport && (
          <Tooltip
            isDisabled={!locked}
            label={t('workflow.field_lock_current_step_explainer_simple')}
            hasArrow
            shouldWrapChildren
          >
            <Button
              isDisabled={locked}
              size="sm"
              variant="outline"
              onClick={onClickEdit}
            >
              {answer ? t('common.edit') : t('common.answer')}
            </Button>
          </Tooltip>
        )}
      </HStack>
      <Box minHeight={1}>
        <Description>{answer ? answer.answer : ''}</Description>
      </Box>
      <HStack justifyContent="space-between">
        {canEdit ? <AccessBadge field={field} /> : null}
        {answer?.creator && !field.form && (
          <Answerer creator={answer.creator} answeredAt={answer.created_at} />
        )}
      </HStack>
    </Stack>
  );
};

export default TextAnswer;
