import LanguageDetect from 'languagedetect';
import { format } from 'date-fns';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  Spacer,
  Stack,
  Text
} from '@chakra-ui/react';
import CoverMedia from 'components/CoverMedia';
import Description from 'components/Description';
import MembersButton from 'components/MembersButton';
import TranslateButton from 'components/TranslateButton';
import Vote from 'components/Vote';
import AttachmentList from 'features/attachment/AttachmentList';
import CommentSection from 'features/comment/CommentSection';
import { Plans, hasPlan } from 'features/hive/hiveUtils';
import api from 'utils/api';
import CardStepAccordions from './CardStepAccordions';
import CardActionMenu from './CardActionMenu';
import CardStep from './CardStep';
import ContributorFeedback from './ContributorFeedback';

const detector = new LanguageDetect();

const CardBody = ({
  authenticated,
  card,
  code,
  updateCard,
  canEdit,
  isAdminOrCollectionManager,
  isAdminOrAssigneeOrCollectionManager,
  onChangeContributors,
  onClickVote,
  onClickDelete,
  onClickEdit,
  onClickViewReport,
  onClickChangeCollection
}) => {
  const { t } = useTranslation();

  const [translations, setTranslations] = useState(null);
  const [showTranslated, setShowTranslated] = useState(false);

  const languages = detector.detect(`${card?.title} ${card?.description}`, 2);

  const handleOnClickTranslate = async () => {
    if (!translations) {
      try {
        const { data } = await api.post('/services/translate', {
          texts: [card.title, card.description]
        });
        setTranslations(data.translations);
      } catch (e) {
        console.log(e);
      }
    }
    setShowTranslated(!showTranslated);
  };

  return (
    <Stack spacing={8}>
      <CoverMedia
        position="relative"
        object={card}
        height={['300px', null, '200px']}
        hideIfEmpty
      />
      <Flex alignItems="center" justifyContent="space-between">
        <HStack spacing={4} width="full">
          <MembersButton
            showCount={false}
            code={code}
            max={3}
            members={card.contributors}
            onChangeMembers={onChangeContributors}
            modalTitle={t('common.contributors')}
            canEdit={canEdit}
            canRemoveSelf={false}
          />
          <Stack spacing={0}>
            {card.contributors.length > 0 && (
              <Text fontSize="sm" fontWeight="bold">
                {card.contributors.length === 1
                  ? card.contributors[0].first_name
                  : `${card.contributors.length} ${t('common.contributors')}`}
              </Text>
            )}
            <Text fontSize="xs">
              {format(new Date(card.created_at), 'dd MMM yyyy, HH:mm')}
            </Text>
          </Stack>
          <Spacer />
          {card.collection?.workflow?.use_card_votes ? (
            <Vote
              voted={card.voted}
              downVotes={card.metrics.downvotes}
              upVotes={card.metrics.upvotes}
              onClickVote={onClickVote}
            />
          ) : null}
          {canEdit ? (
            <Box>
              <CardActionMenu
                card={card}
                collection={card.collection}
                onClickDelete={onClickDelete}
                onClickEdit={onClickEdit}
                onClickViewReport={onClickViewReport}
                onClickChangeCollection={onClickChangeCollection}
                isAdminOrCollectionManager={isAdminOrCollectionManager}
              />
            </Box>
          ) : null}
        </HStack>
      </Flex>
      <Stack spacing={6}>
        <Flex alignItems="center" justifyContent="space-between">
          <Heading fontSize="lg">
            {translations && showTranslated ? translations[0] : card.title}
          </Heading>
        </Flex>
        <Stack spacing={4}>
          {(card.collection.workflow
            ? card.collection.workflow.use_card_description
            : true) && (
            <Description>
              {translations && showTranslated
                ? translations[1]
                : card.description}
            </Description>
          )}
        </Stack>
        {languages &&
          languages.length > 0 &&
          languages[0][0] !== 'english' &&
          languages[0][1] > 0.2 && (
            <TranslateButton
              alignSelf="flex-start"
              showTranslated={showTranslated}
              onClick={handleOnClickTranslate}
            />
          )}
        <AttachmentList
          code={code}
          modelConfig={{
            singular: 'card',
            plural: 'cards',
            modelId: card.id
          }}
          canEdit={canEdit}
          gridColumns={[2, 3, 4]}
        />

        {authenticated &&
          (isAdminOrAssigneeOrCollectionManager ? (
            <>
              <CardStep
                code={code}
                card={card}
                filter={{ step__isnull: true }}
                noEmptyState
              />
              <CardStepAccordions card={card} code={code} />
            </>
          ) : (
            <ContributorFeedback card={card} />
          ))}
        <CommentSection
          commentCount={card.metrics.comments}
          cardId={card.id}
          updateCard={updateCard}
          code={code}
          isAdminOrAssigneeOrCollectionManager={
            isAdminOrAssigneeOrCollectionManager
          }
        />
        {hasPlan(card.collection.hive?.plan, Plans.Freemium) && (
          <>
            <Divider />
            <Text>
              <Trans
                t={t}
                i18nKey="card.powered_by_hives"
                components={[
                  <Text />,
                  <Button
                    variant="link"
                    colorScheme="blue"
                    as="a"
                    href="https://hives.co"
                    target="_blank"
                  />,
                  <Text />,
                  <Button
                    variant="link"
                    colorScheme="blue"
                    as="a"
                    href="https://hives.co"
                    target="_blank"
                  />
                ]}
              />
            </Text>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default CardBody;
