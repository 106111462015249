import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { Divider, Stack, Text, useToast } from '@chakra-ui/react';
import CardForm from 'features/card/CardForm';
import CollectionList from 'features/collection/CollectionList';
import StepModalHeader from 'components/StepModalHeader';
import ScrollableContainer from 'components/ScrollableContainer';
import { useConfetti } from 'providers/ConfettiProvider';
import {
  getCollectingSingular,
  getYourCollectingSingular
} from 'features/collection/collectionUtils';
import api from 'utils/api';
import { cardFilterToArray, generateCardPayload } from './cardUtils';
import { useUi } from 'providers/UiProvider';

const CardCreationForm = ({ isOpen, onClose, isMobile = false }) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [selectedCollection, setSelectedCollection] = useState(null);

  const { pathname } = useLocation();
  const { collectionId } = useParams();
  const queryClient = useQueryClient();
  const { fire } = useConfetti();
  const toast = useToast();

  const { t } = useTranslation();

  const { slug } = useParams();
  const navigate = useNavigate();
  const { filter } = useUi();

  useEffect(() => {
    if (isMobile && isOpen) {
      if (pathname.startsWith('/collection/')) {
        const collection = queryClient.getQueryData([
          'collection',
          collectionId
        ]);
        if (collection) {
          setSelectedCollection(collection);
          setCurrentStepIndex(1);
        } else {
          setCurrentStepIndex(0);
        }
      } else {
        setCurrentStepIndex(0);
      }
    }
  }, [isMobile, isOpen, pathname, queryClient, collectionId]);

  const lastStepIndex = 1;

  const handleOnSelectCollection = collection => {
    setSelectedCollection(collection);
  };

  const createCardMutation = useMutation(
    card => api.post(`/cards?hive=${slug}`, card, { timeout: 0 }),
    {
      onSuccess: ({ data: card }) => {
        fire();
        const cards = queryClient.getQueryData([
          'cards',
          ...cardFilterToArray(filter)
        ]);
        if (cards) {
          const pages = cards.pages.map(page => {
            return {
              ...page,
              count: page.count + 1
            };
          });
          if (pages.length > 0) {
            pages[0].results = [card, ...pages[0].results];
          }
          queryClient.setQueryData(
            ['cards', { hive: slug }, ...cardFilterToArray(filter)],
            {
              ...cards,
              pages
            }
          );
        }
        if (collectionId || selectedCollection?.id) {
          navigate(
            `/${slug}/collection/${
              collectionId ? collectionId : selectedCollection.id
            }`
          );
        }
        onClose();
        toast({
          title: t('card.contribution_thank_you'),
          status: 'success',
          position: 'bottom-right',
          isClosable: true
        });
      }
    }
  );

  const handleOnCreateCard = async card => {
    await createCardMutation.mutateAsync(
      generateCardPayload(
        collectionId ? collectionId : selectedCollection?.id,
        card
      )
    );
  };

  return (
    <>
      <Stack spacing={4}>
        {currentStepIndex === 0 && (
          <>
            <StepModalHeader
              currentTitle={t('card.create_submission')}
              currentStepIndex={currentStepIndex}
              setCurrentStepIndex={setCurrentStepIndex}
              lastStepIndex={lastStepIndex}
              onClose={onClose}
            />
            <Text>{t('card.pick_a_collection_to_contribute_to')}</Text>
            <ScrollableContainer maxHeight="70vh">
              <CollectionList
                open
                itemOnClick={collection => {
                  handleOnSelectCollection(collection);
                  setCurrentStepIndex(currentStepIndex + 1);
                }}
                columns={[1]}
              />
            </ScrollableContainer>
          </>
        )}
        {currentStepIndex === 1 && (
          <>
            <StepModalHeader
              currentTitle={t('card.submit_type', {
                type: getCollectingSingular(selectedCollection.type)
              })}
              currentStepIndex={currentStepIndex}
              setCurrentStepIndex={setCurrentStepIndex}
              lastStepIndex={lastStepIndex}
              onClose={onClose}
            />
            <Text noOfLines={4}>
              <Trans
                i18nKey="card.submit_type_for_the_collection_title"
                values={{
                  type: getYourCollectingSingular(
                    selectedCollection.type
                  ).toLowerCase(),
                  title: `"${selectedCollection.title}"`
                }}
              />
            </Text>
            <Divider />
            <CardForm
              titleOverride={selectedCollection?.workflow?.title_override}
              useDescription={
                selectedCollection?.workflow?.use_card_description
              }
              useImage={selectedCollection?.workflow?.use_card_image}
              workflowId={selectedCollection?.workflow?.id}
              collection={selectedCollection}
              isOpen={currentStepIndex === 1}
              onSubmit={handleOnCreateCard}
            />
          </>
        )}
      </Stack>
    </>
  );
};

export default CardCreationForm;
