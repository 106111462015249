import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import {
  Button,
  CloseButton,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Image,
  Stack,
  Text
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartSimple,
  faHome,
  faRectangleHistory,
  faShareNodes
} from '@fortawesome/pro-regular-svg-icons';

const NavigationDrawer = ({ logo, isOpen, onClose }) => {
  const { t } = useTranslation();

  const { slug } = useParams();

  const NavigationButton = ({ to, icon, text }) => (
    <Button
      as={Link}
      to={to}
      onClick={onClose}
      variant="link"
      justifyContent="flex-start"
      paddingY={4}
    >
      <HStack>
        <FontAwesomeIcon icon={icon} fixedWidth />
        <Text>{text}</Text>
      </HStack>
    </Button>
  );

  return (
    <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent position="relative">
        <CloseButton
          position="absolute"
          top={2}
          right={2}
          size="lg"
          alignSelf="flex-end"
          onClick={onClose}
        />
        <DrawerHeader maxWidth="85%">
          <Link to="/" onClick={onClose}>
            <Image src={logo} height={8} />
          </Link>
        </DrawerHeader>
        <DrawerBody>
          <Stack spacing={4}>
            <Flex flexDir="column">
              <NavigationButton
                to={`/${slug}`}
                icon={faHome}
                text={t('common.home')}
              />
              <NavigationButton
                to={`/${slug}/dashboard`}
                icon={faChartSimple}
                text={t('common.dashboard')}
              />
              <NavigationButton
                to={`/${slug}/channels`}
                icon={faShareNodes}
                text={t('common.channels')}
              />
              <NavigationButton
                to={`/${slug}/collections`}
                icon={faRectangleHistory}
                text={t('common.my_collections')}
              />
            </Flex>
            {/* <Divider />
            <Text variant="muted">{t('common.folders')}</Text> */}
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default NavigationDrawer;
