import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Badge,
  Button,
  Flex,
  HStack,
  Heading,
  Hide,
  Progress,
  Stack,
  Text,
  Tooltip
} from '@chakra-ui/react';
import AccessBadge from 'components/AccessBadge';
import MembersButton from 'components/MembersButton';
import { useQuery } from 'react-query';
import api from 'utils/api';
import IconTooltip from 'components/tooltips/IconTooltip';

const VoteResults = ({
  field,
  options,
  cardId,
  onClickEdit,
  forReport,
  locked
}) => {
  const { t } = useTranslation();
  const { data: selections } = useQuery(
    ['selections', { card: cardId }, { option__field: field.id }],
    async () => {
      const { data } = await api.get(
        `/selections?card=${cardId}&option__field=${field.id}` //&option__field=${field.id}`
      );
      return data;
    }
  );

  return (
    <Stack spacing={8}>
      <Flex alignItems="center" justifyContent="space-between">
        <HStack spacing={4} alignItems="center" justifyContent="space-between">
          <Heading fontSize="md">{field.field}</Heading>
          <IconTooltip color="gray" label={field.help_text} />
          {/* {forReport && <Text variant="muted">{selections?.length}</Text>} */}
        </HStack>
        {forReport ? (
          <Badge>{t('common.voting')}</Badge>
        ) : (
          <Tooltip
            isDisabled={!locked}
            label={t('workflow.field_lock_current_step_explainer_simple')}
            hasArrow
            shouldWrapChildren
          >
            <Button
              isDisabled={locked}
              size="sm"
              variant="outline"
              onClick={onClickEdit}
            >
              {t('common.answer')}
            </Button>
          </Tooltip>
        )}
      </Flex>
      {options.length > 0 && (
        <Stack spacing={8}>
          {options.map(option => (
            <HStack alignItems="flex-end" spacing={8} key={option.id}>
              <Stack width="full" spacing={2}>
                <Flex alignItems="center" justifyContent="space-between">
                  <Text variant="muted">{option.option}</Text>
                  <HStack spacing={4}>
                    {option.selected && (
                      <Badge>{t('card.your_selection')}</Badge>
                    )}
                    <Hide below="lg">
                      <Text fontWeight="bold">{`${option.percentage} %`}</Text>
                    </Hide>
                  </HStack>
                </Flex>
                <Progress rounded="lg" value={option.percentage} />
              </Stack>
              <MembersButton
                isDisabled={
                  selections?.filter(vote => vote.option.id === option.id)
                    .length < 1
                }
                members={
                  selections
                    ? [
                        ...selections
                          ?.filter(vote => vote.option.id === option.id)
                          ?.map(vote => vote.creator)
                      ]
                    : []
                }
                modalTitle={t('common.votes_for_option', {
                  option: option.option
                })}
              />
            </HStack>
          ))}
        </Stack>
      )}
      {!forReport && <AccessBadge field={field} />}
    </Stack>
  );
};

export default VoteResults;
