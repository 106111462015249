import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useColorModeValue,
  useDisclosure,
  useMediaQuery,
  useToast
} from '@chakra-ui/react';
import LoginModal from 'components/modals/LoginModal';
import api from 'utils/api';
import CommentForm from './CommentForm';
import CommentList from './CommentList';

const CommentSection = ({
  cardId,
  commentCount,
  updateCard,
  code,
  isAdminOrAssigneeOrCollectionManager
}) => {
  const [isMobile] = useMediaQuery('(max-width: 62em)');
  const [selectedComment, setSelectedComment] = useState(null);
  const modalBackgroundColor = useColorModeValue('white', 'gray.700');
  const { t } = useTranslation();
  const toast = useToast();

  const {
    isOpen: showCommentModal,
    onOpen: openCommentModal,
    onClose: closeCommentModal
  } = useDisclosure();

  const {
    isOpen: showLoginModal,
    onOpen: openLoginModal,
    onClose: closeLoginModal
  } = useDisclosure();

  const { data: me } = useQuery('me', { enabled: false });

  const queryClient = useQueryClient();

  const updateCommentMetrics = (commentId, add = true) => {
    const comments = queryClient.getQueryData(['comments', cardId]);
    const pages = comments.pages.map(page => ({
      ...page,
      results: page.results.map(c =>
        c.id === commentId
          ? {
              ...c,
              metrics: {
                replies: add ? c.metrics.replies + 1 : c.metrics.replies - 1
              }
            }
          : c
      )
    }));
    queryClient.setQueryData(['comments', cardId], {
      ...comments,
      pages
    });
  };

  const createCommentMutation = useMutation(
    comment => api.post('/comments', comment),
    {
      onSuccess: ({ data: comment }) => {
        if (comment.parent) {
          const comments = queryClient.getQueryData([
            'replies',
            comment.parent
          ]);
          const pages = comments
            ? comments.pages.map(page => ({
                ...page,
                count: page.count + 1
              }))
            : [
                {
                  count: 1,
                  next: null,
                  previous: null,
                  results: [comment]
                }
              ];
          if (pages.length > 0) {
            pages[pages.length - 1].results = [
              ...pages[pages.length - 1].results,
              comment
            ];
          }
          queryClient.setQueryData(['replies', comment.parent], {
            ...comments,
            pages
          });
          updateCommentMetrics(comment.parent, true);
        } else {
          const comments = queryClient.getQueryData(['comments', cardId]);
          const pages = comments.pages.map(page => {
            return {
              ...page,
              count: page.count + 1
            };
          });
          if (pages.length > 0) {
            pages[0].results = [comment, ...pages[0].results];
          }
          queryClient.setQueryData(['comments', cardId], {
            ...comments,
            pages
          });
        }
        const card = queryClient.getQueryData(['card', cardId]);
        updateCard({
          ...card,
          metrics: {
            ...card.metrics,
            comments: card.metrics.comments + 1
          }
        });
      }
    }
  );

  const deleteCommentMutation = useMutation(
    () => api.delete(`/comments/${selectedComment.id}`),
    {
      onSuccess: () => {
        if (selectedComment.parent) {
          const comments = queryClient.getQueryData([
            'replies',
            selectedComment.parent
          ]);
          const pages = comments.pages.map(page => ({
            ...page,
            count: page.count - 1,
            results: page.results.filter(c => c.id !== selectedComment.id)
          }));
          queryClient.setQueryData(['replies', selectedComment.parent], {
            ...comments,
            pages
          });
          updateCommentMetrics(selectedComment.parent, false);
        } else {
          const comments = queryClient.getQueryData(['comments', cardId]);
          const pages = comments.pages.map(page => ({
            ...page,
            count: page.count - 1,
            results: page.results.filter(c => c.id !== selectedComment.id)
          }));
          queryClient.setQueryData(['comments', cardId], {
            ...comments,
            pages
          });
        }
        const card = queryClient.getQueryData(['card', cardId]);
        updateCard({
          ...card,
          metrics: {
            ...card.metrics,
            comments: card.metrics.comments - 1
          }
        });
      }
    }
  );

  const updateCommentMutation = useMutation(
    comment => api.patch(`/comments/${selectedComment.id}`, comment),
    {
      onSuccess: ({ data: comment }) => {
        if (selectedComment.parent) {
          const comments = queryClient.getQueryData([
            'replies',
            selectedComment.parent
          ]);
          const pages = comments.pages.map(page => ({
            ...page,
            results: page.results.map(c =>
              c.id === selectedComment.id ? comment : c
            )
          }));
          queryClient.setQueryData(['replies', selectedComment.parent], {
            ...comments,
            pages
          });
        } else {
          const comments = queryClient.getQueryData(['comments', cardId]);
          const pages = comments.pages.map(page => ({
            ...page,
            results: page.results.map(c =>
              c.id === selectedComment.id ? comment : c
            )
          }));
          queryClient.setQueryData(['comments', cardId], {
            ...comments,
            pages
          });
        }
      }
    }
  );

  const handleOnCreateComment = async (comment, closeModal) => {
    if (me) {
      try {
        await createCommentMutation.mutateAsync({ ...comment, card: cardId });
        toast({
          title: 'Comment was successfully added.',
          status: 'success',
          position: 'bottom-right',
          isClosable: true
        });
      } catch {
        toast({
          title: 'Could not add comment. Please try again later.',
          status: 'error',
          position: 'bottom-right',
          isClosable: true
        });
      }
    } else if (code) {
      openLoginModal();
    }
  };

  const handleOnCreateReply = async ({ value: comment }, closeModal) => {
    if (me && selectedComment) {
      try {
        await createCommentMutation.mutateAsync({
          comment,
          card: cardId,
          parent: selectedComment.id
        });
        closeModal();
        toast({
          title: 'Reply was successfully added.',
          status: 'success',
          position: 'bottom-right',
          isClosable: true
        });
      } catch {
        toast({
          title: 'Could not add reply. Please try again later.',
          status: 'error',
          position: 'bottom-right',
          isClosable: true
        });
      }
    } else if (code) {
      openLoginModal();
    }
  };

  const handleOnDeleteComment = async (comment, closeModal) => {
    setSelectedComment(comment);
    try {
      await deleteCommentMutation.mutateAsync();
      closeModal();
      toast({
        title: 'Comment was successfully deleted.',
        status: 'success',
        position: 'bottom-right',
        isClosable: true
      });
    } catch {
      toast({
        title: 'Could not delete comment. Please try again later.',
        status: 'error',
        position: 'bottom-right',
        isClosable: true
      });
    }
  };

  const handleOnUpdateComment = async (form, closeModal) => {
    try {
      await updateCommentMutation.mutateAsync({
        comment: form.value
      });
      closeModal();
      toast({
        title: 'Comment was successfully updated.',
        status: 'success',
        position: 'bottom-right',
        isClosable: true
      });
    } catch {
      toast({
        title: 'Could not update comment. Please try again later.',
        status: 'error',
        position: 'bottom-right',
        isClosable: true
      });
    }
  };

  const initialCommentLimit = isMobile ? 3 : null;

  return (
    <Stack spacing={8} pb={8}>
      <CommentForm onSubmit={handleOnCreateComment} />
      <CommentList
        limit={initialCommentLimit}
        cardId={cardId}
        code={code}
        onCreateReply={handleOnCreateReply}
        onDeleteComment={handleOnDeleteComment}
        onUpdateComment={handleOnUpdateComment}
        handleOnSetSelectedComment={comment => setSelectedComment(comment)}
        selectedComment={selectedComment}
        isAdminOrAssigneeOrCollectionManager={
          isAdminOrAssigneeOrCollectionManager
        }
      />
      {initialCommentLimit && commentCount > initialCommentLimit && (
        <Button variant="link" onClick={openCommentModal}>
          {t('button.view_all_nr_comments', { nr: commentCount })}
        </Button>
      )}
      <Modal
        size="full"
        onClose={closeCommentModal}
        isOpen={showCommentModal}
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <Stack
            position="sticky"
            py={2}
            top={0}
            bg={modalBackgroundColor}
            zIndex={9999}
          >
            <ModalCloseButton />
            <ModalHeader>
              {`${commentCount} ${t('common.comments')}`}
            </ModalHeader>
          </Stack>
          <ModalBody py={8}>
            <CommentList
              cardId={cardId}
              code={code}
              onCreateReply={handleOnCreateReply}
              onDeleteComment={handleOnDeleteComment}
              onUpdateComment={handleOnUpdateComment}
              handleOnSetSelectedComment={comment =>
                setSelectedComment(comment)
              }
              selectedComment={selectedComment}
              isAdminOrAssigneeOrCollectionManager={
                isAdminOrAssigneeOrCollectionManager
              }
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <LoginModal isOpen={showLoginModal} onClose={closeLoginModal} />
    </Stack>
  );
};

export default CommentSection;
