import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { MDXChakraTheme } from 'components/CustomMDXEditor';

const theme = extendTheme({
  colors: {
    chart: {
      fill: 'rgba(50, 150, 150, 0.2)',
      stroke: 'rgb(50, 150, 150)'
    }
  },
  components: {
    Badge: {
      baseStyle: {
        px: 2,
        py: 0.5
      }
    },
    Button: {
      baseStyle: {
        _focus: {
          boxShadow: 'none'
        },
        flexShrink: 0
      },
      variants: {
        link: () => ({
          minWidth: 0
        }),
        filter: ({ selected = false, ...props }) => ({
          bg: mode(
            selected ? 'gray.200' : null,
            selected ? 'gray.600' : null
          )(props),
          color: mode('gray.800', 'whiteAlpha.900')(props),
          _hover: {
            bg: mode('gray.300', 'gray.600')(props)
          },
          border: '1px',
          borderColor: mode(
            'gray.200',
            selected ? 'gray.700' : 'whiteAlpha.300'
          )(props)
        }),
        overlay: ({ ...props }) => ({
          bg: 'blackAlpha.700',
          color: 'white',
          _hover: {
            bg: mode('gray.900', 'gray.700')(props)
          },
          _active: {
            bg: mode('gray.900', 'gray.700')(props)
          }
        })
      }
    },
    FormLabel: {
      baseStyle: props => ({
        fontSize: 'xs',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: mode('gray.500', 'whiteAlpha.700')(props)
      })
    },
    Link: {
      baseStyle: props => ({
        color: mode('blue.500', 'blue.200')(props)
      })
    },
    Modal: {
      defaultProps: {
        size: 'xl'
      }
    },
    Tabs: {
      baseStyle: {
        tabpanel: {
          px: 0
        }
      },
      variants: {
        fresh: props => ({
          tab: {
            color: mode('gray.500', 'gray.600')(props),
            rounded: 'md',
            _selected: {
              bg: mode('gray.100', 'gray.600')(props),
              color: mode('gray.800', 'whiteAlpha.900')(props)
            },
            fontWeight: 'bold'
          },
          tablist: {
            bg: null,
            padding: 1,
            rounded: 'lg',
            borderWidth: 1,
            width: 'fit-content'
          }
        })
      }
    },
    Text: {
      variants: {
        description: props => ({
          overflowWrap: 'break-word',
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word',
          a: {
            color: mode('blue.500', 'blue.200')(props)
          }
        }),
        muted: props => ({
          fontSize: 'xs',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          color: mode('gray.500', 'whiteAlpha.700')(props)
        }),
        light: props => ({
          color: mode('gray.500', 'whiteAlpha.700')(props)
        }),
        error: props => ({
          color: mode('red.500', 'red.200')(props)
        }),
        validation: ({ error, ...props }) => ({
          color: mode(
            error ? 'red.500' : 'teal.500',
            error ? 'red.200' : 'teal.200'
          )(props)
        })
      }
    },
    Table: {
      defaultProps: {
        colorScheme: 'gray'
      }
    }
  },
  fonts: {
    heading: 'Poppins',
    body: 'Poppins'
  },
  sizes: {
    container: {
      xs: '460px'
    }
  },
  styles: {
    global: props => ({
      ':not(.chakra-dont-set-collapse) > .chakra-collapse': {
        overflow: 'initial !important'
      },
      body: {
        overflow: 'hidden'
      },
      ...MDXChakraTheme(props), //workaround, cuz inline-styling .mdx (via sx prop on a Box wrapper) ruins its dropdown zIndex
      '.wordcloud div': {
        svg: {
          maxHeight: '60vh',
          flex: 1,
          'g text': {
            cursor: 'pointer'
          }
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }
    })
  }
});

export default theme;
