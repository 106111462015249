import { Heading, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import Illustration from './Illustration';

const EmptyState = ({
  button = null,
  title,
  description,
  filename,
  ...rest
}) => (
  <SimpleGrid
    borderWidth={1}
    columns={[1, 2]}
    padding={12}
    rounded="lg"
    spacing={8}
    {...rest}
  >
    <Illustration filename={filename} />
    <Stack spacing={4} alignSelf="center">
      <Heading fontSize="2xl">{title}</Heading>
      {description && <Text>{description}</Text>}
      {button}
    </Stack>
  </SimpleGrid>
);

export default EmptyState;
